import React, {useRef} from 'react'
import Logo from '../New assets/Pictures/MERCYLAND.png'
import { Link, NavLink } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import '../styles/Navbarrewrite.css'

function Navbarrewrite() { 

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav')
  }
  return (
    <header className='bg-green-500 px-[10px] lg:px-[30px]'>
      <Link className='w-[180px] sm:w-[200px]' to='/'><img className='w[100%]' src={Logo} alt='' /></Link>

        <div className='flex'>
          <nav ref={navRef}>
            <NavLink onClick={showNavbar} activeClassName='active' to='/'>Home</NavLink>
            <NavLink onClick={showNavbar} activeClassName='active' to='/about'>About</NavLink>
            {/*<a onClick={showNavbar} href='/#service'>Services</a>
            <a onClick={showNavbar} href='/#our-estate'>Our Estate</a>*/}
            <NavLink onClick={showNavbar} activeClassName='active' to='/properties'>Properties</NavLink>
            <NavLink onClick={showNavbar} activeClassName='active' to='/gallery'>Gallery</NavLink>
            <NavLink onClick={showNavbar} activeClassName='active' to='/contact'>Contact</NavLink>
            <NavLink onClick={showNavbar} activeClassName='active' to='/blog'>Blog</NavLink>
            <button className='nav-button nav-close-button' onClick={showNavbar}>
              <FaTimes/>
            </button>
          </nav>
            <button className='nav-button' onClick={showNavbar}>
              <FaBars />
            </button>
        </div>
        
    </header>
  )
}

export default Navbarrewrite
