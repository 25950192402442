import React from 'react'
import '../styles/Footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SiThreads } from 'react-icons/si';
import { RiTwitterXFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div>
      <footer className='bg-green-900'>
        <div className="container">
            <div className="row mb-[50px]">
                <div className="col-lg-5 col-sm-6">
                    <div className="single-box">
                        <p>
                            Mercyland Estates Limited was founded to be a company for everyone who dares to become a
                            property owner in any part of Nigeria and indeed the world. Our organization brings 15+ years of
                            experience committed to providing a way for those who want to become land/property owners
                            without hassels. We have made over 1,500 people proud land and property owners! Mercyland
                            Estates Limited is in business purposely to play a role in making it convenient for as many as possible
                            to have easy access to secured, Government approved, relatively cheap plots/acres of land. When it
                            comes to Mercyland Estates Limited services, our integrity, transparency, attention to detail, and
                            professional approach set us apart. We walk with you every step along your journey to become a
                            land/property owner.
                        </p>
                    
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="single-box">
                        <h2>Quick Links</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/properties">Properties</Link></li>
                        <li><Link to="/gallery">Gallery</Link></li>
                        <li><Link to="/mercyland-details">Mercyland Delight Estate</Link></li>
                        <li><Link to="/mercylandfarm-details">Mercyland Farm Estate</Link></li>
                    </ul>
                    </div>                    
                </div>
                
                <div className="col-lg-3 col-sm-6">
                    <div className="single-box">
                        <h2 className='textst' style={{color:"black"}}>Visit Us</h2>
                        <p style={{fontStyle:"italic"}}>4th Floor, 89 Allen Avenue Opp. GTB Ikeja, Lagos</p>
                        <p style={{fontStyle:"italic"}}>Macflory's Place, Canaan Land Market, Redemption City, Mowe, Ogun State.</p>
                        <h2 className='textst' style={{color:"black"}}>Phone</h2>
                        <div className='phoneText'>
                        <p>+234 803 894 9316 </p>
                        <p>+234 916 988 0123</p>
                        <p>+234 916 988 0124</p>
                        <p>+234 916 988 0125</p>
                        <h2 className='mt-3'>Email</h2>
                        <p>info@mercylandgroup.com</p>
                        </div>
                        
                        
                        <h2>Follow us on</h2>
                        <p className="socials">
                            <a href='https://www.facebook.com/MercyLandEstates/' target='blank'><FacebookIcon /></a>
                            <a href='https://mobile.twitter.com/Mercyland_?t=WvpRVAHZGje0eh5VgrF6Nw&s=09' target='blank'><RiTwitterXFill /></a>
                            <a href='https://instagram.com/mercylandestates?igshid=YmMyMTA2M2Y=' target='blank'><InstagramIcon /></a>
                            <a href='https://mercylandgroup.com/#' target='blank'><LinkedInIcon /></a>
                            <a href='https://tinyurl.com/ms2crcxu' target='blank'><SiThreads /></a>
                        </p>
                    </div>
                </div>
            </div>
            <div className='text-white text-center bg-black w-full py-3'>
                Copyright &copy; {new Date().getFullYear()}. All rights reserved.        
            </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer
