import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Image from '../New assets/Update Files/contact us 601x400.jpg';
import '../styles/Contact.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [iduser, setIduser] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const sendEmail = (formData) => {
    const templateId = 'template_5i3szxm';

   
    const serviceId = 'service_y4q7lnb';
    const userId = 'r5h5m3qV4kuBlGmw9'; 

    // Prepare the email data
    const emailData = {
      to_email: formData.email,
      from_name: formData.name,
      to_phone: formData.phone,
      message: formData.iduser,
    };

    // Send the email using emailjs-com
    emailjs
      .send(serviceId, templateId, emailData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Email failed to send:', error);
      });
  };

  const submit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    }
    if (!phone.trim()) {
      newErrors.phone = 'WhatsApp phone number is required';
    }

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      // Do something with the form data (optional)
      const formData = {
        name,
        email,
        iduser,
        phone,
      };

      // Call the function to send email
      sendEmail(formData);
    }

    setName('');
    setPhone('');
    setEmail('');
    setIduser('');
  };

  return (
    <div className='bg-green-500 pb-20 pt-[100px] '>
      <div className='container'>
        <div className='grid items-center grid-col lg:grid-cols-2 gap-8'>
          <div>
            <h1 className='contact mb-4 text-center bg-[#780699] p-1 text-slate-100'>Contact Us</h1>
            <div className='bg-slate-100 shadow-1 p-1 rounded-tl-[20px] rounded-br-[20px]'>
              <img className='h-full rounded-tl-[20px] rounded-br-[20px]' src={Image} alt='' />
            </div>
          </div>
          <div className='info flex-1 border-1 p-4 rounded-lg border-slate-100'>
            
              <form className='flex flex-col'>
                <label>Full Name</label>
                {errors.name && <p className='text-red-500 m-0'>{errors.name}</p>}
                <input value={name} required onChange={(e) => {setName(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, name: '' }));}} id='name' className='border-1 text-[14px] text-slate-500 py-1 rounded-lg px-2 mb-2 h-10 border-green-500 bg-slate-100' name='user_name' placeholder='Enter full name' type='text' />
                <label>WhatsApp Phone Number</label>
                {errors.phone && <p className='text-red-500'>{errors.phone}</p>}
                <input value={phone} required onChange={(e) => {setPhone(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));}} id='phone' className='border-1 text-[14px] text-slate-500 py-1 rounded-lg px-2 mb-2 h-10 border-green-500 bg-slate-100' name='user_number' placeholder='Enter whatsapp phone number' type='number' />
                <label>Email</label>
                {errors.email && <p className='text-red-500'>{errors.email}</p>}
                <input value={email} required onChange={(e) => {setEmail(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, email: '' }));}} id='email' className='border-1 text-[14px] text-slate-500 py-1 rounded-lg px-2 mb-2 h-10 border-green-500 bg-slate-100' name='user_email' placeholder='Enter email...' type='email' />
                <label>Message</label>
                <textarea value={iduser} required onChange={(e) => {setIduser(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, iduser: '' }));}} id='iduser' className='border-1 text-[14px] text-slate-500 border-green-500 p-3 rounded-lg bg-slate-100' rows='6' placeholder='Enter message...' name='message' type='text'></textarea>
              </form>
              <button onClick={submit} className='py-2 px-3 bg-[#780699] rounded-lg active:bg-slate-500 text-white mt-2 text-[18px]'>Send Message</button>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Contact
