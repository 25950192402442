import React, { useState, useEffect, useContext } from 'react';
import { RiWallet3Line, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Menu } from '@headlessui/react';
import { HouseContext } from './HouseContext';

const PriceRangeDropdown = () => {
  const { price, setPrice } = useContext(HouseContext);

  const [isOpen, setIsOpen] = useState(false);

  const prices = [
    {
      value: 'Price range (any)',
    },
    {
      value: '1,000,000 - 1,500,000',
    },
    {
      value: '1,500,000 - 2,000,000',
    },
    {
      value: '2,000,000 - 3,000,000',
    },
    {
      value: '3,000,000 - 4,000,000',
    },
    {
      value: '4,000,000 - 5,000,000',
    },
    {
      value: '5,000,000 - 10,000,000',
    },
  ]

  return <Menu as='div' className='dropdown relative w-full lg:max-w-[296px] cursor-pointer;'>
    <Menu.Button 
      onClick={() => setIsOpen(!isOpen)}
      className='w-full text-left flex h-[64px] items-center px-[18px] border rounded-lg'
      >
        <RiWallet3Line className='dropdown-icon-primary text-2xl mr-[18px] text-green-500' />
        <div>
          <div className='text-[15px] font-medium leading-tight'>{price}</div>
          <div className='text-[13px]'>Choose Price Range</div>   
        </div>   
        {isOpen ? (
            <RiArrowUpSLine className='text-2xl text-green-500 ml-auto' />
            ) : (
              <RiArrowDownSLine className='text-2xl text-green-500 ml-auto' />
            )
          }     
    </Menu.Button>

    <Menu.Items className='px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;'>
      {prices.map((price, index) => {
        return (
          <Menu.Item
            onClick={() => setPrice(price.value)}
            className='cursor-pointer hover:text-green-700 transition'
            as='li'
            key={index}
          >
            {price.value}
          </Menu.Item>
        )
      })}
    </Menu.Items>
  </Menu>;
};

export default PriceRangeDropdown;
