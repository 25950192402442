import React, { useState } from 'react'
import '../styles/About.css'

function AboutProfile(props) {
    const [expand, setExpand] = useState(false)

    const toggleExpand = () => {
        setExpand(!expand)
    }
  return (
    <div className='lg:flex gap-[50px] '>
          <div className='w-[200px] mb-2'>
          <img className='w-[80px] lg:w-[100px] rounded-full absolute lg:ml-[25px]' src={props.image} alt='' />
          </div>
        <div className='max-w-[600px] py-4 px-4 shadow-1 bg-white'>
            <h3 className='text-center px-3 py-2 bg-green-500 text-[20px] text-white shadow-1'>{props.office}</h3>
            <h4 className='text-center'>{props.name}</h4>
            <p className={`content ${expand ? 'expand' : ''}`}>{props.status}</p>
            {props.status.length > 100 && (
            <button className='text-green-500 active:text-red-300' onClick={toggleExpand}>
            {expand ? 'Read less' : 'Read more'}
            </button>
      )}
        </div>
    </div>
  )
}

export default AboutProfile
