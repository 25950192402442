import React from 'react'
import Videopage from '../New assets/Mercyland_Delight_Estate.mp4'

function Video() {
  return (
    <div className='py-5 px-4'>
      <video src={Videopage} controls='controls' className='mx-auto rounded-lg' />
    </div>
  )
}

export default Video
