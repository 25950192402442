import React from 'react'
import Banner from '../Properties/Banner';
//import Search from '../Properties/Search';
import HouseList from '../Properties/HouseList';

function Properties() {
  return (
    <div>
      <Banner />
      <HouseList />
    </div>
  )
}

export default Properties

