import {useContext} from 'react';
import { HouseContext } from './HouseContext';
import House from './House';
import { Link } from 'react-router-dom';
import { ImSpinner2 } from 'react-icons/im';
import '../styles/Properties.css';

const HouseList = () => {
  const { houses, loading } = useContext(HouseContext);

  if (loading) {
    return (
      <ImSpinner2 className='mx-auto animate-spin text-[#34DA31] text-4xl mt-[100px] mb-[100px]' />
    );
  }

  if (houses.length < 1) {
    return <div className='text-center text-3xl text-gray-400 mt-20 mb-20'>Sorry, nothing found</div>;
  }

  return <section className='bg-[#CEFFCD] pt-20 pb-20 mt-10'>
    <div className='container mx-auto'>
      <div className='addressLink grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14'>
        {houses.map((house, index) => {
          return (
            <Link to={`/property/${house.id}`} key={index}>
              <House house={house} />
            </Link>
          )
        })}
      </div>
    </div>
  </section>;
};

export default HouseList;
