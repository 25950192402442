import React from 'react'
import '../styles/Blog.css';
import {TiTimes} from 'react-icons/ti';
import LegalImage from '../New assets/important-legal-documents-that-one-comes-across-during-the-property-purchase.jpg'

function Legaldocumentation({isOpen, toggleLegalModal}) {
  
  return (
    <div>
      {isOpen && (
      <div>
        <div onClick={toggleLegalModal} className='main'>
          </div>
          <div className='close-bar bg-white fixed right-[8px] top-[100px] lg:right-[80px] lg:top-[150px] rounded-lg text-red-500 text-[25px]' onClick={toggleLegalModal}><TiTimes />
        </div>
        <div className='background h-[600px] w-[90%] lg:max-h-[500px] lg:max-w-[70%]'>
          <div className='modal-header bg-green-500'>
            <h3>THE IMPORTANCE OF LEGAL DOCUMENTATION IN REAL ESTATE TRANSACTIONS</h3>
            
          </div>
          <div className='modal-body mt-[70px] p-4'>
            <div className='w-[80%] mx-auto border-gray-300 border-4 shadow-1 mb-[30px] mt-[90px] sm:my-20'>
              <img className='w-[100%]' src={LegalImage} alt='' />
            </div>

            <p className='text-justify'>
              Investing in real estate involves different processes but one of the most important parts is ensuring that you have the proper documents that show that you are the owner of the property. This is a rule of common sense that the law also practically demands. The principle of ownership and possession is such that unless you have the legal title to a property, your possession of it does not automatically confirm legal status on you as the owner in law. In the eye of the law, there are documents that will show to the world that a property belongs to you or at least that a valid transaction had taken place.
              </p>

              <h3>WHAT ARE LEGAL DOCUMENTS?</h3>
              <p>
              Legal documents are certain records and evidence which has full authenticity and validity in the judicial system. Legal documents follow all rules and regulations according to the Laws of the Constitution and can be produced as evidence whenever there is any proceedings or trials. They are accepted as legally supportive proofs. <br/><br/>

              Legal documents state some contractual relationship or grants some rights. They are always drawn up by a Lawyer. Examples of legal documents include: a certificate, deeds, wills, contract agreements, court writs or process, legislative act, etc.<br/><br/>

              Our major focus of discussion today is the Importance of Legal Documentation in Real Estate. It is pertinent to note that by the provision of Sections 21 and 22 of the Land Use Act, no transactions affecting land in Nigeria either by way of assignment, mortgage, transfer of possession, sublease, or otherwise shall be carried out without first obtaining the consent of the  Governor of the concerned State. As such, any transaction done or any instrument which purports to confer on, or vest any interest or right of land in any person in contravention of Sections 21 and 22 of the Land Use Act is null and void.<br/><br/>

              It is on this background that the scope of our discussion on the importance of Legal Documentation in real estate is based. The list of documents required when buying, leasing or letting a property in Nigeria include:<br/><br/>

              <ol>
                <li>&bull; Deed of Assignment,</li>
                <li>&bull; Sale of Land Agreement,</li>
                <li>&bull; Certificate of occupancy,</li>
                <li>&bull; Right of occupancy,</li>
                <li>&bull; Letter of Allocation,</li>
                <li>&bull; Power of Attorney,</li>
                <li>&bull; Deed of Mortgage,</li>
                <li>&bull; Governors' consent,</li>
                <li>&bull; Tenancy agreement,</li>
                <li>&bull; Receipt of Purchase.</li>
              </ol>
              <br/>
              <h3>IMPORTANCE OF LEGAL DOCUMENTATION IN REAL ESTATE TRANSACTIONS.</h3>

              When it comes to real estate transactions, the Law recommends that the agreement should be in writing. Any written document is less susceptible to mischievous misrepresentation. Mr. A can deny saying something to Mr. B or give another interpretation to his words, so it is of great essence to have proper documentation during real estate transactions. The whole process of a real estate transaction should be filled with proper accountability from all parties and documentation. A seller can agree to sell his or her property for any sum and it is during the negotiation process that this is sorted out by the parties. Negotiating before reaching a compromise is a process that often takes time in real estate and during this period, offer, counter offer, acceptance and payment of the purchase price are usually discussed back and forth before a final agreement is reached.<br/><br/>
              <h3>Why is it important to have accurate documentation in real estate?</h3>

              <ol className='line-h-5'>
                <li>
                &bull; <span className='font-bold'>A good land documentation system is important to encourage property owners to register their property:</span>  Land as we know is an asset to any economy, individual or organization. It is the basis on which prosperity and wellbeing is formed. Having said this, one of the fundamental reasons for land documentation is to prove title to a land. A good Land documentation system makes it easy and encouraging for a property owner to register and have a conclusive proof of ownership and an evidence to tender in event of dispute on a land.<br/><br/>
                </li>
                <li>
                  &bull; <span className='font-bold'>Generate more internal revenue for the state:</span>  It provides a steady flow of internal generated revenue for the government, which in turn helps improve the growth and sustainability of a state.<br/><br/>
                </li>
                <li>
                  &bull; <span className='font-bold'>Giving property owners a sense of security and value:</span>  Another importance of land documentation is the security, certainty and value it gives to land owners, by providing greater protection against fraudulent claim or possession of a land.<br/><br/>
                </li>
                <li>
                  &bull; <span className='font-bold'>It reduces litigation and conflict:</span>  A good land documentation reduces litigation and conflict. It shows the legal state and history of a land, and whether it is government acquired.<br/><br/>
                </li>
                <li>
                  &bull; <span className='font-bold'>Make it easier for prospective buyers to acquire property:</span>  Land documentation process makes it easy for anyone wanting to buy a property by making it easy to access information and the history of a prospective land.<br/><br/>
                </li>
              </ol>
              
              <h3>Conclusion:</h3>
              Finally, we can all agree that documentation of land documents not only serves as strong evidence either in court or anywhere when tendered, but also gives the buyer such confidence to use the land without fear. It can also serve as a collateral which an individual can use to access loans from financial institutions.<br/><br/>

              It is therefore important that as an investor in real estate or as a real estate firm you should know what governs land ownership and the legal documents used in conveying title for land ownership in the state, where your business is located or where you intend to invest in.

            </p>
          </div>
        </div>
      </div>)}
    </div>
  )
}

export default Legaldocumentation
