import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Scroll = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Perform your scroll action when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname, navigate]);

  return null;
};

export default Scroll;
