// import house images
import House1 from './New assets/House and Land/Ifako.jpg';
import House2 from './New assets/New Property/property adss.jpg';
import House3 from './New assets/New Property/Property sale.jpg';
import House4 from './New assets/Flyers/Featured Property 1 inner.jpg'
import House5 from './New assets/Flyers/FEATURED PROPERTY 2 Outer.jpg'
//import House4 from './assets/img/houses/house4.png';
//import House5 from './assets/img/houses/house5.png';
//import House6 from './assets/img/houses/house6.png';
//import House7 from './assets/img/houses/house7.png';
//import House8 from './assets/img/houses/house8.png';
//import House9 from './assets/img/houses/house9.png';
//import House10 from './assets/img/houses/house10.png';
//import House11 from './assets/img/houses/house11.png';
//import House12 from './assets/img/houses/house12.png';
// import house large images
import House1Lg from './New assets/House and Land/Ifako.jpg';
import House2Lg from './New assets/House and Land/Ikeja_House.jpg';
import House3Lg from './New assets/House and Land/3d_gbagada.jpg';
//import House4Lg from './assets/img/houses/house4lg.png';
//import House5Lg from './assets/img/houses/house5lg.png';
//import House6Lg from './assets/img/houses/house6lg.png';
//import House7Lg from './assets/img/houses/house7lg.png';
//import House8Lg from './assets/img/houses/house8lg.png';
//import House9Lg from './assets/img/houses/house9lg.png';
//import House10Lg from './assets/img/houses/house10lg.png';
//import House11Lg from './assets/img/houses/house11lg.png';
//import House12Lg from './assets/img/houses/house12lg.png';

// import apartments images
import Apartment1 from './New assets/New Property/Property rent.jpg';
import Apartment2 from './New assets/House and Land/Ikeja_House.jpg';
import Apartment3 from './New assets/House and Land/3d_gbagada.jpg';
//import Apartment4 from './assets/img/apartments/a4.png';
//import Apartment5 from './assets/img/apartments/a5.png';
//import Apartment6 from './assets/img/apartments/a6.png';
// import apartments large images
import Apartment1Lg from './New assets/House and Land/Ifako.jpg';
import Apartment2Lg from './New assets/House and Land/Ikeja_House.jpg';
import Apartment3Lg from './New assets/House and Land/3d_gbagada.jpg';
//import Apartment4Lg from './assets/img/apartments/a4lg.png';
//import Apartment5Lg from './assets/img/apartments/a5lg.png';
//import Apartment6Lg from './assets/img/apartments/a6lg.png';

// import agents images
import Agent1 from './assets/img/agents/agent1.png';
import Agent2 from './assets/img/agents/agent2.png';
import Agent3 from './assets/img/agents/agent3.png';
import Agent4 from './assets/img/agents/agent4.png';
import Agent5 from './assets/img/agents/agent5.png';
import Agent6 from './assets/img/agents/agent6.png';
import Agent7 from './assets/img/agents/agent7.png';
import Agent8 from './assets/img/agents/agent8.png';
import Agent9 from './assets/img/agents/agent9.png';
import Agent10 from './assets/img/agents/agent10.png';
import Agent11 from './assets/img/agents/agent11.png';
import Agent12 from './assets/img/agents/agent12.png';

import Land1 from './New assets/New Property/property ads.jpg'

export const housesData = [
  {
    id: 1,
    type: 'House',
    name: 'House 1',
    description:
      '8 Units of 3 bedrooms flat and a 2 bedroom flats',
    image: House2,
    imageLg: House2,
    country: 'Lagos State',
    address: 'Egbeda, Lagos State',
    bedrooms: '3 and 2 bedrooms',
    bathrooms: '',
    surface: '670 sqm',
    year: '',
    price: '110,000,000',
    agent: {
      image: Agent1,
      name: 'Patricia Tullert',
      phone: '0123 456 78910',
    },
  },
  
  
  {
    id: 2,
    type: 'Apartament',
    name: 'Apartament 1',
    description:
      'A newly built 2 bedroom flat (9 units), The property facing main road. Good road network, Adequate security, Conducive Atmosphere, Electricity and much more!',
    image: Apartment1,
    imageLg: Apartment1,
    country: 'Lagos State',
    address: 'Ibadan Street, Ebute-Metta, Oyingbo, Lagos',
    bedrooms: '2',
    bathrooms: '',
    surface: 'sq ft',
    year: '',
    price: '1,500,000',
    agent: {
      image: Agent7,
      name: 'Jawhar Shamil Naser',
      phone: '0123 456 78910',
    },
  },
  {
    id: 3,
    type: 'House',
    name: 'House 2',
    description:
      'A 4 Bedroom flat on a one and half plot of land for sale with 3 shops. The property facing main road.',
    image: House3,
    imageLg: House3,
    country: 'Lagos State',
    address: 'Eyita, Sabo-Ikorodu, Lagos.',
    bedrooms: '4',
    bathrooms: '',
    surface: 'sq ft',
    year: '',
    price: '60,000,000',
    agent: {
      image: Agent8,
      name: 'Juana Douglass',
      phone: '0123 456 78910',
    },
  },
  {
    id: 4,
    type: 'House',
    name: 'House 3',
    image: House4,
    imageLg: House4,
    address: 'Lekki Gardens behind Lagos Business School, Ajah, Lekki, Lagos',
    description: '4 Bedroom Terrace Duplex',
    country: 'Lagos State',
    bedrooms: '4',
    bathrooms: '',
    surface: '465 sq ft',
    year: '',
    price: '95,000,000',
    type: 'House'
},
{
    id: 5,
    type: 'House',
    name: 'House 4',
    image: House5,
    imageLg: House5,
    address: 'Ibeju Lekki, Lagos',
    description: '4 Bedroom Terrace Duplex.',
    country: 'Lagos State',
    bedrooms: '4',
    bathrooms: '',
    surface: '34890 sq ft',
    year: '',
    price: '95,000,000',
    type: 'House'
},
  {
    id: 6,
    type: 'Land',
    name: 'Land 17',
    description:
      'Land for Sale at Shimawa. Good road network, Adequate security, Free from Government acquisition, Ready for development and much more!',
    image: Land1,
    imageLg: Land1,
    country: 'Ogun State',
    address: 'Power Area, Behind RCCG New Auditorium, Shimawa, Ogun State',
    surface: 'sq ft',
    year: '',
    price: '4,500,000',
    agent: {
      image: Agent4,
      name: 'Kaitlyn Gonzalez',
      phone: '0123 456 78910',
    },
  },
  
];
