import React, {useEffect} from 'react'
//import Header from '../helper/Header';
//import Latestnews from '../helper/Latestnews'
import Ourservices from '../helper/Ourservices'
import OurEstate from '../components/OurEstate';
//import Featuredproperty from '../helper/Featuredproperty'
import Featuredprop from '../helper/Featuredprop';
//import Slider from '../helper/Slider';
import HomeSlider from '../helper/HomeSlider';
//import SearchIcon from '@mui/icons-material/Search';
import '../styles/Home.css'
import Whychoose from '../helper/Whychoose';
//import SearchBar from '../helper/SearchBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Motto from '../helper/Motto';
import Video from '../helper/Video';
//import Ads from '../helper/Ads'
import Christmas from '../helper/Christmas';


function Home() {

  return (
  <div className='home-container'>
        <HomeSlider />
        <Motto />
        {/*<Ads />*/}
        {/*<SearchBar />*/}   
        {/*<Latestnews />*/}
        <div className='service' id='service'><Ourservices /></div>
        <Video />
        <Featuredprop />
        {/*<Featuredproperty />*/}
        <div className='our-estate' id='our-estate'><OurEstate /></div>
        <Whychoose />
        {/*<Christmas />*/}
  </div>
  );
  
}

export default Home
