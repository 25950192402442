import React, { useState, useEffect } from 'react'
import '../styles/Ourservices.css'
import SchoolIcon from '@mui/icons-material/School';
import BalanceIcon from '@mui/icons-material/Balance';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EducationConsult from '../pages/EducationConsult';
import { useNavigate } from 'react-router-dom'

function Ourservices() {

  const navigate = useNavigate();
  
  return (
    <div className='services container py-20'>
      <div className='servicesText'>
        <h1>Our Services</h1>
        <p className=''>Mercyland Estates Limited is in business purposely to play a role in making it convenient for as<br></br> many as possible to have easy access to secured, Government approved...</p>
      </div>

      <div className='servicesIcon grid lg:grid-cols-3 mx-auto px-4 gap-2'>
        <button onClick={()=>navigate('/real-estate')}>
          <CorporateFareIcon />
          <h3>Real Estate</h3>
        </button>


        <button onClick={()=>navigate('/education-consult')}>
          <SchoolIcon />
          <h3>Education Consult</h3>
        </button>
        
        
        <button onClick={()=>navigate('/legal-consult')}>
          <BalanceIcon />
          <h3>Legal Consult</h3>
        </button>
        
        
      </div>
    </div>
  )
}

export default Ourservices
