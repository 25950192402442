import React from 'react'
//import MercyFarm from '../helper/MercyFarm'
import Mercyland from '../helper/Mercyland'

function OurEstate() {
  return (
    <div className='estate py-20  bg-slate-500' id='estate'>
      <h1 className='text-center text-white'>Our Estates</h1>
      <Mercyland />
    </div>
  )
}

export default OurEstate
