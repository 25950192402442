import React from 'react'
import { Container } from 'react-bootstrap'
import '../styles/Gallery.css';

function Galleriesvid(props) {
  return (
    <Container>
            {/*<video>
                <source src={props.video} type='video/mp4'/>
  </video>*/}
  {<div className='picture hover:cursor-pointer'>
  <video src={props.video} className='rounded-tl-3xl rounded-br-3xl border-4 border-white hover:cursor-pointer' controls='controls' />
  </div>}

    </Container>
  )
}

export default Galleriesvid
