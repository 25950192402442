import React from 'react'
import {TiTimes} from 'react-icons/ti';
import LegalImage from '../New assets/will-image.jpeg';

function Advantages({isOpenAdvan, toggleAdvanModal}) {
  return (
    <div>
      {isOpenAdvan && (
      <div>
        <div onClick={toggleAdvanModal} className='main'></div>
        <div className='close-bar bg-white fixed right-[8px] top-[100px] lg:right-[80px] lg:top-[150px] rounded-lg text-red-500 text-[25px]' onClick={toggleAdvanModal}><TiTimes /></div>
        <div className='background h-[600px] w-[90%] lg:max-h-[500px] lg:max-w-[70%]'>
          <div className='modal-header bg-green-500'>
            <h3>Advantages Of Making a Will...</h3>
            
          </div>
          <div className='modal-body mt-[70px] p-4'>
            <div className='w-[80%] mx-auto border-gray-300 border-4 shadow-1 mb-[30px] mt-[90px]'>
              <img className='w-[100%]' src={LegalImage} alt='' />
            </div>

            <p className='text-center'>
              Happy Monday to dearly beloved Readers and Welcome to a new week of ….
              </p>

             
              <p className='text-center'>
                <span className='font-bold italic'>Okay so I have gist for you guys..</span><br/><br/>

                You know I like to start out with an exciting gist, more like gossip, lol. Well, on today's table is the case of Chief Alao who died recently due to the Covid complications (yeah Covid-19 is real guys). Leaving so many assets behind, from money in different bank accounts, to properties, cars and other immovable assets, was survived by his two wives and 8 children. Before his death, Chief Alao was involved in a promiscuous affair with one Miss Sophie who happened to have birthed a child for him in the course of their relationship.

                I know some people are waiting for the gist. Oh well, the juicy part of this gist now is that Chief Alao died without making a will, he only told his first wife of his affair with Miss Sophie and his illegitimate child. Now the family members are all hell and bent on who inherits the companies, his properties and other assets belonging to the deceased. Extended family also popping up from nowhere claiming Chief made some kind of ridiculous promise to them and claiming right to the deceased Estate.

                The first wife on the other hand claims to have been legally married to him under the law. The second wife claims to have stood by him and built the estate with the deceased when the first wife was busy living an extravagant life style.

                In all of this, are you thinking what I’m thinking? Because how do these people want to go about this whole hullabaloo.

                This brings us to our discussion for the week.  Last week, we discussed the need to make a Will and what makes a Will valid and we noted the misconceptions many people have when it comes to the notion of making a Will forgetting that in the journey of life, the only certain thing is Death.

                Some go as far as asking questions like Do you want me to die? Is writing a Will when I’m young important?  LOL FOLKS….. How about you faze yourself with questions like; what happens to all the assets I have acquired over the years? Who manages these assets when nature takes its course? Would my close relatives and siblings rub my spouse and children off of these assets? Who takes care of my underage children when I’m not there? Or would you rather pass on without leaving a word on how your spouse or children should be cared for just like Chief Alao?

                I think these questions should make you realize the Importance of making a Will. But hey just incase you still don’t get it, I’ll highlight a few advantages of Making a Will below:<br/><br/>

                <h3>ADVANTAGES OF MAKING A WILL.</h3>

                <ol className='line-h-5'>
                  <li>
                  1. Through the making of a Will, the testator has the opportunity to choose persons who will administer his estate as an executor who he believes are competent enough to manage his assets.<br/><br/>
                  </li>
                  <li>
                  2. Through the making of a Will, the testator can extend the list of his beneficiaries beyond his nuclear family to include friends, etc.<br/><br/>
                  </li>
                  <li>
                  3.	The testator can appoint guardians for children and can also give funeral directives in his will.<br/><br/>
                  </li>
                  <li>
                  4.	The making of a will excludes the rules of inheritance under customary law<br/><br/>
                  </li>
                  <li>
                  5.	There is continuity of an estate covered by a Will.<br/><br/>
                  </li>
                  <li>
                  6.	Making of a Will limits the rate of family disputes as regards distribution of one’s assets.<br/><br/>
                  </li>
                  <li>
                  7.	It saves time and money which will be spent when applying for Letters of Administration from the Probate Registry. Thus when there is a Will, your estate will be administered faster and with lesser complication because your intention will be known.<br/><br/>
                  </li>
                  <li>
                  8.	 It gives the testator peace of mind knowing that he had done what is necessary to secure the future of his loved ones.<br/><br/>
                  </li>
                  <li>
                  9.	A will can be used in making a trust in form of donation to an institution or charity homes.<br/><br/>
                  </li>
                </ol>
                
                <h3>EFFECTS OF DYING WITHOUT A WILL</h3>
                Just like the story I just narrated above, a person who dies without a Will is said to have died Intestate. As such, the Law of intestate Succession is primarily administered. <br/><br/>

                Generally, the Law of Intestate Succession in Nigeria is divided into two. They are:


                <ol>
                  <li>a.	Intestate succession under the Marriage Act</li>
                  <li>b.	Intestate succession under customary law</li>
                </ol><br/><br/>

                <span className='font-bold'>Intestate Succession under the Marriage Act</span><br/>

                Where a deceased person dies intestate but is married in a church or under the law (court), his estate will be distributed in accordance with the provision of the Statute. Hence, the Administration of the Estate laws of the various states govern the administration of a deceased Estate. As such, the spouse and children of the deceased, where none, the relatives of the deceased will then apply for the Letter of Administration which gives them the authority to administer and distribute the estate to persons that are qualified to be beneficiaries under the law. In Lagos state, Section 49 of the Administration Of Estate Law provides that where the deceased is survived by children, the spouse takes the personal chattel and one-third (1/3) of the real estate and two-third (2/3) of the estate goes to his children. Where the deceased is only survived by a spouse and no children, no parents/siblings, the spouse inherits the estate. Where there is no spouse the children inherits the estate and where there are no children or spouse, the parents’ shall inherit the estate. Also, where the deceased is survived by a spouse, and relatives, with no child, then the spouse shall inherit the deceased personal chattels and two-third (2/3) of the deceased estate while the parents or siblings takes the remaining one-third (1/3).<br/><br/>

                <span className='font-bold'>Intestate Succession under Customary Law</span><br/>

                Considering the various customary law practices in Nigeria, the applicable customary law in the case of a deceased who only performed traditional marital rites, is the customary law of the deceased. This is usually the law of place of origin of the deceased.<br/><br/>

                For instance in the Igbo Customary Law, rights and assets of the deceased are solely inherited by the eldest son. Female children and women are excluded from the inheritance of a deceased. The eldest son manages and administers the estate on trust for the benefit of the whole family. Where the deceased marries more than one wife, the eldest sons of all the wives inherits the estate jointly. According to the customs of the Igbo people, where there are no children, the  deceased parents/ siblings inherits the estate but only as trustees to administer the estate of the deceased on behalf of the deceased family.<br/><br/>

                According to the customs of the Yoruba people, the children of the deceased inherits the estate of the deceased. However where it is a woman who dies, the husband inherits all her properties and assets to the exclusion of her share in her maiden family estate. Where the husband dies, the children inherits the estate as a woman is not allowed to inherit the property of her deceased husband.<br/><br/>



                The Northern part of Nigeria adopts the Islamic law of inheritance and the indigenous Native Laws and Custom, depending on the ethnic group or tribe of the deceased. Under the Islam Law of Inheritance, it is believed that one-third (1/3) of the deceased property should be distributed amongst his immediate family excluding the girls while the remaining two-thirds (2/3) of the deceased’s assets are used to support those he was obliged to in his lifetime and who are affected by his death. This is after all burial rites has been performed and all debts incurred by the deceased in his lifetime has been sorted. While the Northern Native Law and Custom generally accepts that the first in line to inherit the deceased’s property are his sons. Females only inherit their mother’s moveable properties.<br/><br/>



                Under the Benin Custom, the eldest surviving son of the deceased inherits the house where the decease lived and died to the exclusion of others and the rest of the assets are distributed amongst the children with the eldest son receiving half of the rights of the deceased while the wife is left with nothing. The wife is seen as property to be inherited in the Benin traditions.<br/><br/>

                <h3>CONCLUSION</h3>
                Writing a Will is probably one of the best things to do for your loves. It is in my opinion a way of acknowledging your own inevitable demise while actively planning for it. After all, we humans are ‘mere mortals’.<br/><br/>

                Thank you for sticking with me on this one guys. I hope this article  did justice highlighting why you should go visit a Lawyer today and make that Will if you haven’t and if you have, this is me literally giving a ‘thumbs up’ for a job well done.<br/><br/>

                <span className='font-bold'>TILL NEXT WEEK…. ‘CIAO’ (in my Spanish accent)</span>

            </p>
          </div>
        </div>
      </div>)}
    </div>
  )
}

export default Advantages
