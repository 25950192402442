import React, {useEffect} from 'react'
import Image from '../New assets/Flyers/MACFLORY EDU INNER FLYER.jpg'
import QuizIcon from '@mui/icons-material/Quiz';

function EducationConsult() {
  

  return (
    <div className=' mx-auto'>
      <h1 className='bg-[#b478c6] pl-5 mb-5 text-white'>Education Consult</h1>
      <div className=' py-10 px-4 text-white mb-5'>
        <h5 className='bg-green-500 py-3 px-4 text-center lg:max-w-[400px] lg:items-left mb-4 shadow-1'>MACFLORY CONSULT</h5>
          {/**<p className='mb-10 font-20 font-bold text-black'>
            Macflory Edu Consult work with students, parents, and schools to improve educational experiences, 
            facilities, and outcomes.
          </p>**/}
          
        <div className='flex flex-col lg:flex-row gap-4'>
          {/**<div>
            <p className='text-black'>
            At Macflory Education Consult, our vision is to provide services that are second to none by adding value 
            through prompt, efficient and reliable delivery, thereby creating a lasting solution everywhere we 
            operate.<br></br>
            VISA ADVISORY: A visa can be the biggest difference between your dream and reality of working or 
            studying’’ abroad…. That is why we provide visa advisory services that are tailored to meet the needs of 
            our customers. We aim to give immigration advice that best accommodates the travel needs of each 
            applicant according to their current circumstance.<br></br>
            STUDY PERMIT: Is a document issued by Immigration that allows a Foreign National to study in another 
            country for a limited time. We ensure that your study permit is processed in a timely and appropriate 
            manner.<br></br>
            EXPRESS PERMANENT RESIDENCY: Refers to a person’s resident status in a country of which they are not 
            a citizen. This is usually for an indefinite period; a person with such status is known as a Permanent 
            Resident. We organize EXPRESS Permanent Residency to Canada and Australia with 90% guaranteed 
            placement.<br></br>
            STUDENT ADMISSION: Our students are guaranteed a 100% admission placement in any University or 
            College in any part of the world.<br></br>
            CONFERENCE PACKAGING: We package conferences to any part of the world. Our packages includes
            group and corporates conferences.<br></br>
            HOLIDAY PACKAGING: Our holiday packages are 2nd to none. Our packages cut across every continent.<br></br>
            OVERSEA JOB PLACEMENT: We currently organize job placement for Nurses, Doctors, and Midwives to 
            Republic of Ireland (GUARANTEED)
            </p>
          </div>**/}
          <div>
            <p className='text-black'>
              <h6 className='mb-1 font-20 font-bold text-black'>
                RESERVATIONS FOR THE IRELAND AUTUMN 2024 TRIP TO DUBLIN HAVE COMMENCED
              </h6>

              The package will run from 7th - 11th   October, 2024.<br/><br/>

              The package to Dublin, Ireland covers:<br/>
              4 nights’ accommodation @ a 4-Star Hotel in Dublin City<br/>
              Buffet Breakfast daily<br/>
              Dublin City Tours and a visit to historic sites.<br/>
              Ireland Visitor's Visa processing services.<br/><br/>

              <h6 className='font-bold text-black'>Total Package: 2,500 euros</h6><br/>
              
              <h6 className='mb-1 font-20 font-bold text-black'>NOT INCLUDED IN THE PACKAGE ARE THE FOLLOWING:</h6>
              
              Flight Tickets are not included: Flight tickets can be requested after the Visa has been approved, the rate will depend on airline choice and class of flight. Flight ticket rates are NOT included in the above quotes.<br/><br/>

              <h6 className='mb-1 font-20 font-bold text-black'>BOOKING REQUIREMENTS FOR THE PACKAGE:</h6>
              
              The following should be received before the booking deadline:
              1) Data pages of the international passport of all traveling family members.
              2) Non-refundable reservations and regular Visa appointment booking fee of 250 euros, per person
              3) Completed Visa information form for all adults (18 years and above). A copy of the form can be requested via email to macfloryedu@gmail.com<br/><br/>

              <h6 className='mb-1 font-20 font-bold text-black'>DO YOU HAVE A VALID IRISH VISA?</h6>
              
              If you have a valid Irish visa, the total package per adult is 2400 euros.
              A 50% deposit is required to secure your reservations before the deadline.<br/><br/>

              <h6 className='mb-1 font-20 font-bold text-black'>RESERVATION DEADLINE:</h6>
              
              This offer is valid till 15th July 2024. Note, however, that it is strongly recommended that booking be made as soon as possible to cover the visa processing time and give you enough time to buy flight tickets.

              Please note that prices are subject to change after the stated deadline, due to exchange rate fluctuations.

            </p>
          </div>
          
          
        </div>
        
      </div>
      
      <div className='w-auto lg:max-w-[800px] p-3 shadow-1 mx-4 mb-5 mx-auto' >
        <img className='w-[100%]' src={Image} alt='' />
      </div>

      {/**<div className='mx-auto px-4'>
        <h5 className=''>Why Choose Macflory Education Consult?</h5>
        <div className='ml-4'>
          <p>
          &bull; Our visa process is Prompt, Reliable and Efficient<br></br>
          &bull; Experienced staffs to meet your needs<br></br>
          &bull; Guaranteed placement in top ranking universities in Ireland, UK, USA, Africa, Asia, Europe, Australia 
          and other parts of the world<br></br>
          &bull; Your choice of over 30,000 undergraduate and post-graduate degree courses<br></br>
          &bull; Our university partners are ranked in the Top 100 Universities in the World<br></br>
          &bull; 100% of students achieve a university, Post-Graduate, Masters, Ph.D. or Doctoral placement<br></br>
          &bull; Personalized guidance and support as a central part of your study plan<br></br>
          &bull; You will receive the best academic lectures to put you ahead of the rest<br></br>
          &bull; We provide unparalleled support for getting you admission and settling you in, from Visa 
          applications to accommodation we are with our students every step of the way<br></br>

          </p>
        </div>
      </div>**/}
      <div className='mx-auto px-4'>
        <h5 className=''>VISA INFORMATION:</h5>
        <div className='ml-4'>
          <p>
            All applicants will be required to appear for biometrics data capture, including children. Hence families are encouraged to submit their details before the deadline to facilitate appointment booking and visa processing on time.<br/>
            Note further that, proof of funds in the form of a 6-month bank statement, proof of occupation (Title document/Tenancy agreement), and other relevant supporting documents will be required for submission of applications online.<br/><br/>

            For further inquiries, please call or WhatsApp<br/>
            Chinyere @ +234 8038949316<br/>
            Mark @+7 960 354-61-49<br/>
            Funbi @ +234 9169880124<br/>
            Remi @ +234 9169880123<br/>
            Akinloye @ +234 9169880125<br/>
            Maimuna @ +243 7035133873<br/>

          </p>
        </div>
      </div>

      <div className='bg-green-600 py-10 px-4'>
        <h2 className='text-center bg-green-100 py-3 px-4 mb-4'>Our Achievements</h2>
        <h5 className='text-white'>Get Admission In Canada</h5>
        <p className='text-white'>
        Canadian universities and colleges are among the best in the world. In 2012, four Canadian universities 
        ranked in the Top 100 of the ARWU Shanghai Ranking and 22 in the Top 500; 3 ranked in the Top 50 of 
        the QS University Ranking and 20 in the Top 500; and 5 ranked in the Top 100 of the Times Higher 
        Education World University Rankings and 8 in the Top 200. This is certainly an amazing record for a 
        single country. Studying in Canada or getting an admission to study at any of the Canadian College or 
        University should be a top priority for those who may want to pursue a Quality education in their 
        respective areas of interest and professions. General survey has proven that one out of every three 
        people all over the world who are under the age of thirty years old desire to study in Canada. This is 
        because Canada is one of the best countries with one of the best economies in the whole world. In 
        Canada, almost 25 percent of students have an immigrant background; this is a reflection of the 
        country’s openness towards international students. For information on getting started on pursuing your 
        academic career in Canada, please contact us.
        </p>

        

        <h5 className='text-white'>Basic Steps to getting admitted into Canadian Institutions</h5>
        <p className='text-white'>
        The first thing you have to consider is if the Canadian program or Institution you select will meet your 
        objectives, and will help you to avoid a crucial loss of time, energy and money. Before you seek 
        admission to a Canadian College, School, University or other type of Post-secondary Institution, it is 
        important to have answers to a number of questions:
        </p>

        <h5 className='text-white'>Can I achieve my dreams and goals with the course I desire to pursue in Canada?</h5>
        <p className='text-white'>
          It is advisable that you inquire from competent and qualifying authorities before seeking admission into 
          a University, College, or School (in Canada or elsewhere). You can also Contact us for assistance. We will 
          give you all the information you need to know.<br></br><br></br>
          <span className='text-yellow-200'>
            NOTE: We can help you make the best choice and guide you through the entire process. Contact us for 
            more information.
          </span>
        </p>
      </div>

      <div className='px-4 py-10 grid grid-col lg:grid-cols-2 gap-5'>
        <div className='lg:max-w-100 shadow-1'>
          <img className=' hidden flex-l lg:flex justify-end' src={Image} alt='' />
        </div>
        <div>
          <div className='flex gap-2 mb-4'>
            <h1 className='font-bold'>FAQ</h1>
            <div className='text-green-600 font-xl'><QuizIcon /></div>
          </div>

          <div>
            <h5>About studying in Canada</h5>
            <p>
              Below we have listed answers to some questions often asked by Nigerian students who want to study in 
              Canada. We strongly recommend that you book an appointment with our trained consultants, to ensure 
              that you are provided with a personalized advice, as answers to various of these questions can change 
              depending on your academic, financial and personal situation.<br></br>
              It is recommended that Nigerian students wanting to study in Canada should start planning for their 
              studies in Canada, 6 months to 1 year in advance. This is particularly important as it could take Canadian 
              Embassy up to 3-5 months to process an application for a study visa to Canada from Nigeria.
            </p>
          </div>
          <div>
            <h5>About Canada student visa applications</h5>
            <p>
              The processing time for a Canadian study visa could take up to 3 to 5 months, so it is recommended that 
              you apply as early as possible. The time frame varies depending on where (i.e. the country) your 
              application is processed, the application method (i.e. paper or online) used, and the number of 
              application received by CIC during that period. You should avoid the risk of last minute visa applications 
              by ensuring that you apply early to the educational institution in Canada, so that they provide you with 
              the necessary documents (letter of acceptance), which you will need for your visa application. This will 
              ensure that you have enough time to prepare necessary documents and meet other requirements for 
              your visa application.

            </p>
          </div>
        </div>
      </div>

      <div className='bg-[#34da31] px-4 text-white py-4'>
        <h2 className='text-center'>General Admission</h2>
        <p>
          All applicants must submit to the Admissions Office the following documents and adhere to the 
          following procedures:
        </p>
        <p>
          &bull;Complete Admission Application Form with Macflory Educational Application fee (NGN 25,000.00)
          &bull;An official high school transcript (NECO/WAEC) (if applicable);
          &bull;Official Transcripts showing the completion of a Bachelor’s degree or its equivalent for a Master’s 
          degree program.
          &bull;Statement of Purpose or Personal Essay (A brief statement of the applicant’s purpose of study)
          &bull;Copy of student’s passport (inside cover and signature page)
          &bull;Documentation confirming their high school completion was awarded, if not already indicated on 
          official transcripts; and
          &bull;Proof of English language proficiency.
        </p>
        <p>
          Finally, it is important for you to know that major languages used in Canada are English and French. But 
          most of the universities offer courses in English language. So you need to show your efficiency by means 
          of English Language tests. IELTS is most widely recognized and accepted English proficiency test in
          Canada, however, some universities and colleges accept TOEFL. The score that you must obtain in these 
          language tests vary from institution to institution and course of study. Some universities even seek for 
          other medium of English language test assessment. For MBA, students may be asked for two years of 
          work experience before being admitted to the university. In most of the cases GMAT is compulsory for 
          MBA students.<br></br>
          University/College may require Tuition deposit which must be paid into the school Account.
          Consultancy Fee – NGN 100.000:00 Plus 5% VAT must be paid later after admission. Application Fee (this 
          is charged by each school or school chosen) School Transcript<br></br>

          

          Get assistance with procuring your relevant Student and non-immigrate visas and permits to select 
          destinations within and outside Africa for all purposes such as visits, tourism, events, medicals, temporal 
          & study, work, business and more<br></br>
          Avoid common mistakes lots of us Nigerians make in processing our various travel visa to our favourite 
          destinations. Our assistance is accessible 24hours, from experts with decade years of experience in the 
          travel industry. Let our experience work for you in securing your access to travel around the world. 
          Reward your passion for travel with results this time.<br></br>
          We’ve got an in-house team that specialises in denial cases so if you have been denied visas once or 
          repeatedly to your favourite travel destinations, we might be able to assist you with results as we’ve 
          helped numerous travellers such as yourself.<br></br>
          At Macflory Edu Consult, we have a passion for our customers and aim to bring the most vital travel 
          information your way. This is a guideline to making the process smoother by assisting you in your visa 
          application process. Most embassies require evidence of confirmed flight and hotel reservation; you can 
          book cheap flights and cheap hotels at Macflory Edu Consult.<br></br>
          Please note that Visa issuance is determined only by the embassies and relevant authorities. Macflory 
          Edu Consult merely assist in facilitating embassy/immigration-requirements to increase your chances of 
          securing respective visas with our success rate at 99.9%. Visas do not guarantee entry into any country.
          It is still the exclusive preserve of immigration officials at point of entry.<br></br>
          Macflory Edu Consult can assist you and your loved ones in securing an American visiting or business 
          visa. Our in-house team of US Visa specialist are excited and look forward to assisting you and your 
          loved ones with converting your denial visa to a success… even if you’ve been repeatedly denied. Let our 
          decade years of experience help you
 
        </p>
      </div>
    </div>
  )
}

export default EducationConsult
