import React from 'react'
import LandImage from '../New assets/Flyers/MERCYLAND Farm Inner flyer.jpg'

function MercylandFarmdetails() {
  return (
    <div className='pt-[120px] '>
      <h2 className='text-center font-bold'>MERCYLAND IDERA ESTATE & IDERA FARM ESTATES 4</h2>
      <div>
        <div className='container grid grid-col lg:grid-cols-2 gap-4 pt-20 pb-10'>
          <div>
            <h4>PROPERTY DESCRIPTION</h4>
            <p className='text-[18px]'>
              A fertile land for farming and residential affordable land for sale within an 
              estate close to Lagos (Sholo/Apindan by Siun, under Obafemi Owode LGA of Ogun State).
            </p>

            <h5>LOCATION</h5>
            <p className='text-[18px]'>MERCYLAND IDERA ESTATE & FARM ESTATE 4, SIUN-OGUN STATE is located in 
              SHOLO/APINDAN via Ashipa town, Siun, off Abeokuta Expressway, A 20 minutes drive from RCCG 
              Redemption Camp, Mowe.
              
            </p>
            <h5>TITLE</h5>
            <p>
              Perimeter Survey & Ogun State Land Charting Information Certificate (LCIC) 
            </p>
            
          </div>
          <div className='shadow-1'>
          <img className='rounded-3xl w-[100%]' src={LandImage} />
          </div> 
        </div>
        <div className='mb-10'>
          <h4 className='text-center bg-green-500 rounded-3xl text-white w-[200px] mx-auto mt-3 py-2'>PRICE</h4>
          <p className='font-bold text-[25px] text-center'>₦2,000,000 per acre (6 plots) & ₦500,000 per plot</p>
        </div>

          <div className='bg-slate-100 text-center py-20'>
            <h1 className='text-center font-bold mb-4'>Payment Plan</h1>
            <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
              <p className=''>Outright payment - N2,000,000 per acre</p>
              <p className=''>Monthly payment for 12 months - N173,400</p>
              <p className=''>Monthly payment for 54 months - N40,000</p>
              <p className=''>Outright payment - N500,000 per plot</p>
              <p className=''>Monthly payment for 8 months - N50,000 (N200,000 initial deposit required)</p>
              <p className=''>Monthly payment for 40 months - N15,000</p>
            </div>
          </div>
      </div>
      <div className='text-center py-20'>
        <h1 className='text-center font-bold mb-4'>Terms & Conditions apply</h1>
        <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
          <h3 className='text-center'>OTHER FEES</h3>
          <p>
            Deed of Assignment - ₦200,000 per acre & ₦50,000 per plot<br/><br/>
            Survey Fees - ₦200,000 per acre & per plot<br/><br/>
            Survey in business name cost - ₦250,000 per acre<br/><br/>
            Development Fee - ₦250,000 per acre / ₦50,000 per plot (required at time of physical development)
          </p>
        </div>
      </div>

      <div>
        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='bg-white shadow-1 p-5'>
              <h1 className='font-bold mb-4'>ESTATE FEATURES</h1>
              <div className='text-[20px]'>
                <p>Close to Lagos</p>
                <p>Good motorable road network</p> 
                <p>Close to colleges & industries</p>
                <p>Train station is about 11 minutes drive from the Estate junction</p>
              </div>
            </div>

            <div className=''>
              <h1 className='text-center font-bold mb-4'>NEIGHBOURHOOD</h1>
              <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
                <p>Nestle Water Factory, Emzor Pharmaceuticals</p>
                <p>Olam Industries, OPIC New Makun City</p>
                <p>Numerous Industries around the Sagamu interchange</p>
                <p>Numerous schools with boarding facilities around the Sagamu-Abeokuta axis</p>
                <p>Numerous residential estates around Mowe-Ofada Ogun State</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='text-center py-20 font-bold'>
            <h4>SIZE</h4>
            <p className='text-[25px] mb-5'>One Acre (6 plots) is approximately 4000 square.meters</p>

            <h4>DOCUMENTS RECEIVED AFTER PAYMENT</h4>
            <p className='text-[25px]'>Receipts of Purchase & Letter of Allocation, Deed of Assignment and Survey Plan</p>
          </div>
        </div>

        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
              <h1 className='text-center font-bold mb-4'>Bank Details</h1>
              <p>Mercyland Estates Ltd <br/>
                1130071953 <br/>
                Zenith Bank
              </p>
            </div>

            <div className='bg-white shadow-1 p-5'>
              <h3 className='font-bold mb-4'>INSPECTION</h3>
              <p className='text-[20px]'>FREE inspection to the site is every week. It is important that you call the office phone lines to book ahead. 
                by calling the office line (08038949316) for proper scheduling.<br/><br/>
              </p>
            </div>
          </div>

          <div className='text-center font-bold text-[25px] py-10'>
            <p>Don't be left out! Seize the opportunity to own a land today!!!<br/>
              Call/WhatsApp: 08038949316, 09169880123, 09169880124, 09169880125
            </p>
          </div>
        </div>

        

      </div>
    </div>
  )
}

export default MercylandFarmdetails
