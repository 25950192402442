import React from 'react'
import LandImage from '../New assets/Flyers/REAL ESTATE INNER PAGE.jpg'

function RealEstate() {
  return (
    <div className='pt-[120px] pb-20'>
      <div className='container text-[18px]'>
        <h1 className='text-center mb-10'>Real Estate</h1>
        <p className='mb-10'>
          Mercyland Estates Limited was founded to be a company for everyone who dares to become a property owner in any part of Nigeria and indeed the world. Our organization brings 15+ years of experience committed to providing a way for those who want to become land/property owners without hassles. We have made over 1,500 people proud land and property owners!<br/><br/>
          Mercyland Estates Limited is in business purposely to play a role in making it convenient for as many as possible to have easy access to secured, Government approved, relatively cheap plots/acres of land. When it comes to Mercyland Estates Limited services, our integrity, transparency, attention to detail, and professional approach set us apart. We walk with you every step along your journey to become a land/property owner. Mercyland Estates Limited makes buying and selling of real estate as cost effective as possible and maintaining the highest level of service delivery.
        </p>
      </div>
      
      
      <div>
        <h2 className='text-center'>Our Estates</h2>
        <div className='container grid grid-col lg:grid-cols-2 gap-4 py-20'>
          <div>
            <h4>MERCYLAND IDERA ESTATE & FARM ESTATE 4</h4>
            <p className='text-[18px]'>
              <h5>PROPERTY DESCRIPTION</h5> 
              <p>A fertile land for farming and residential affordable land for sale within an 
                estate located at Asero/Aare/Apena/Ponlade/Apindan-Ashipa via Siun, off Abeokuta Expressway close to Sagamu Interchange (20 minutes drive from RCCG Redemption City, Mowe), under Obafemi Owode LGA of Ogun State.</p> 
            </p>
            <h5>TITLE</h5>
            <p>
            Perimeter Survey & Ogun State Land Charting Information Certificate (LCIC) 
            </p>
            <h4 className='text-center bg-green-500 rounded-3xl text-white w-[200px] mx-auto mt-3 py-2'>PRICE</h4>
            <p className='font-bold text-[25px]'>₦2,000,000 per acre (6 plots) & ₦500,000 per plot</p>
          </div>
          <div className='shadow-1'>
          <img className='rounded-3xl w-[100%]' src={LandImage} alt='' />
          </div>
        </div>

          <div className='bg-slate-100 text-center py-20'>
            <h1 className='text-center font-bold mb-4'>Payment Plan</h1>
            <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
              <p className=''>Outright payment - ₦2,000,000 per acre & ₦400,000 per plot.</p>
              <p className=''>Monthly payment for 12 months -₦130,000.</p>
              <p className=''>Monthly payment for 24 months -₦65,000.</p>
              <p className=''>Monthly payment for 54 months -₦30,000.</p>
              <p className=''>Outright payment ₦500,000 per plot</p>
              <p className=''>Monthly payment for 8 months -₦38,000 (₦150,000 deposit required).</p>
              <p className=''>Monthly payment for 45 months -₦10,000 DOWN PAYMENT.</p>
            </div>
          </div>
      </div>
      <div className='text-center py-20'>
        <h1 className='text-center font-bold mb-4'>Terms & Conditions apply</h1>
        <div className='container bg-[#af6ec2] p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
          <h3 className='text-center'>OTHER FEES</h3>
          <p>
            Deed of Assignment - ₦150,000 per acre & ₦45,000 per plot<br/><br/>
            Survey Fees - ₦200,000 per acre & ₦ per plot<br/><br/>
            Survey in business name cost - ₦250,000 per acre<br/><br/>
            Development Fee - ₦250,000 per acre / ₦50,000 per plot (required at time of physical development)
          </p>
        </div>
      </div>

      <div>
        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='bg-white shadow-1 p-5'>
              <h1 className='font-bold mb-4'>ESTATE FEATURES</h1>
              <div className='text-[20px]'>
                <p>Close to Lagos</p>
                <p>Good motorable road network</p> 
                <p>Close to colleges & industries</p>
                <p>Train station is about 11 minutes drive from the Estate junction</p>
              </div>
            </div>

            <div className=''>
              <h1 className='text-center font-bold mb-4'>NEIGHBOURHOOD</h1>
              <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
                <p>Nestle Water Factory</p>
                <p>Olam Industries OPIC New Makun City</p>
                <p>Numerous Industries around the Sagamu interchange</p>
                <p>Numerous schools with boarding facilities around the Sagamu-Abeokuta axis</p>
                <p>Numerous residential estates around Mowe-Ofada Ogun State</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='text-center py-20 font-bold'>
            <h4>SIZE</h4>
            <p className='text-[25px] mb-5'>One Acre (6 plots) is approximately 4000 square.meters</p>

            <h4>DOCUMENTS RECEIVED AFTER PAYMENT</h4>
            <p className='text-[25px]'>Receipts of Purchase & Letter of Allocation, Deed of Assignment and Survey Plan</p>
          </div>
        </div>

        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
              <h1 className='text-center font-bold mb-4'>Bank Details</h1>
              <p>Mercyland Estates Ltd <br/>
                1130071953 <br/>
                Zenith Bank
              </p>
            </div>

            <div className='bg-white shadow-1 p-5'>
              <h3 className='font-bold mb-4'>INSPECTION</h3>
              <p className='text-[20px]'>FREE Inspection to the site is Thursdays & Saturdays by 9am. It is very important that you book ahead 
                by calling the office line (08038949316) for proper scheduling.<br/><br/>
              </p>
            </div>
          </div>

          <div className='text-center font-bold text-[25px] py-10'>
            <p>Don't be left out! Seize the opportunity to own a land today!!!<br/>
              Call/WhatsApp: 08038949316, 09169880123, 09169880124, 09169880125
            </p>
          </div>
        </div>

      </div>

      <div className='container pt-20'>
        <h2>MERCYLAND DELIGHT ESTATE</h2>
        <p>
          Mercyland Delight Estate is an over 20 acre exquisite residential development located in the heart of the<br/><br/>

          New Lagos along the Ajah-Ibeju Lekki corridor. Purchasing a plot in our Mercyland Delight Estate takes<br/><br/>

          Away the pain of dealing with Land grabbers and brings the joys of making your dream home a reality.<br/><br/> 

          Investing in Mercyland Delight Estate guarantees a Return on Investment of between 50% to 100% <br/><br/>

          Within the next 24 months. Your investment is safe, secured and free from any form of Government <br/><br/>

          Acquisition.<br/><br/>

          Land Texture/Status: 100% Dry<br/><br/>

          Location: Maidan/Awoyaya(5 Minutes' Drive from Mayfair Gardens, Awoyaya and 6 minutes to Lakowe <br/><br/>

          Lakes Golf and Country Estate).
        </p>

          <h4>Estate Features</h4>
          <ul>
              <ol>• Gated community</ol>

              <ol>• Paved road network</ol>

              <ol>• Green areas/landscaping</ol>

              <ol>• Electrification</ol>

              <ol>• Recreational centres/sporting arena</ol>

              <ol>• Free from land grabbers</ol>

              <ol>• Certificate of Occupancy</ol>

              <ol>• Instant Allocation</ol>
              
              </ul>

              <h4>Neighbourhood</h4>
              <ul>
                <ol>• Mayfair Gardens</ol>

                <ol>• Lakowe Lakes Golf and Country Estate</ol>

                <ol>• Lagos Business School</ol>

                <ol>• Novare Mall(ShopRite)</ol>

                <ol>• Coscharis Motors</ol>

                <ol>• Green Springs School,Awoyaya</ol>

                <ol>• AutoNation</ol>
              </ul>
          
              <div className='flex flex-col lg:flex-row gap-3 lg:justify-center'>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Size/Plot</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>600 sq.m</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Title</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>C of O</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Original Price</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>N 16 million</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Current Payment Plan</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Outright payment (₦16M)</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>No Installment</p>
                </div>
            </div>
            
            <div className='py-4'>
                <h4>Documents Received after payment</h4>
                <ul>
                    <li>
                        &#8226; Receipts of Purchase & Letter of Allocation
                    </li>
                    <li>
                        &#8226; Deed of Assignment
                    </li>
                    <li>
                        &#8226; Contract of Sale and Survey Plan
                    </li>
                </ul>
            </div>

            <div className='shadow-1 px-3 py-2 flex flex-col gap-3 lg:max-w-[800px] lg:justify-center'>
                <h4>Bank Details</h4>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Mercyland Estates Limited</p>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Zenith Bank Plc &ndash; 1011739921</p>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Guaranty Trust Bank &ndash; 0138007282</p>
            </div>
      </div>
    </div>
  )
}

export default RealEstate
