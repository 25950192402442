import React from 'react';
import '../styles/Houses.css';

function Houses(props) {
  return (
    <div className='max-w-[500px] mx-auto container lg:mb-10 w-full'>
      <div className='objects bg-white p-4'>
        <div className='feat--image--contain rounded-tl-[100px] rounded-br-[100px]'>
          <div className='pictured w-[100%] py-4'>
            <img className='feat--image w-[100%] border-2 border-green-500 rounded-tl-[100px] rounded-br-[100px]'
            src={props.image} alt='' />
          </div>
        </div>
          <h4 className='my-3'>
            {props.title}
          </h4>
          <p className='mb-2 hover:underline'>
            {props.address}
          </p>
          <div className=''>
            <button className='hover:bg-green-500 px-4 py-2 rounded-lg border-green-500 border-1 text-green-500'>
              More Info
            </button>
          </div>
      </div>
    </div>
    
  )
}

export default Houses
