import React from 'react';
import '../styles/Motto.css';

function Motto() {
  return (
    <div>
        <div className='text--animate bg-[#9338AD] text-center text-white py-6 lg:py-20 border-b-4 mt-[-90px] lg:mt-[-70px]'>
            <h3>MERCYLAND ESTATE LIMITED</h3>
            <p className='text-[18px]'>
                We are a company for everyone who dares to become a property owner in any part of Nigeria and indeed the world
            </p>
            <div> 
            <div className='service-estate lg:mt-0 flex max-w-[600px] mx-auto'>
            <a className='mx-auto ' href='/#service'><button className='btn1 bg-green-500 px-8 py-1 rounded-lg text-white border-none bg-opacity-[0.8] text-[18px]'>Our Services</button></a> 
            <a className='mx-auto' href='/#our-estate'><button className='btn2 bg-green-500 px-8 py-1 rounded-lg text-white border-none bg-opacity-[0.8] text-[18px]'>Our Estate</button></a>
            </div>
        </div>
        </div>

        
    </div>
  )
}

export default Motto
