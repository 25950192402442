import React from 'react'

function AboutProfile(props) {
  return (
    <div className='lg:flex gap-[50px]'>
          <div className='w-[200px] mb-2'>
          <img className='w-[80px] lg:w-[100px] rounded-full absolute lg:ml-[30px]' src={props.image} alt='' />
          </div>
        <div className='max-w-[600px] py-4 px-4 shadow-1 bg-white'>
        <h3 className='text-center px-3 py-2 bg-green-500 text-[20px] text-white shadow-1'>{props.office}</h3>
        <h4 className='text-center'>{props.name}</h4>
        <p className=''>{props.status}</p>
        </div>
    </div>
  )
}

export default AboutProfile
