import Image1 from './New assets/Flyers/Featured Property 1 inner.jpg'
import Image2 from './New assets/Flyers/FEATURED PROPERTY 2 Outer.jpg'
import Image3 from './New assets/Flyers/featured properties 3 outer.jpg'


export const feathouseData = [
    {
        id: 1,
        image: Image1,
        title: '4 Bedroom Terrace Duplex',
        address: 'Lekki Gardens behind Lagos Business School, Ajah, Lekki, Lagos',
        description: '4 Bedroom Terrace Duplex at Lekki Gardens behind Lagos Business School, Ajah, Lekki, Lagos',
        country: 'Lagos State',
        bedrooms: '4',
        bathrooms: '',
        surface: '465 sq ft',
        year: '',
        price: '95,000,000',
        type: 'House'
    },
    {
        id: 2,
        image: Image2,
        title: '4 Bedroom Terrace Duplex',
        address: 'Ibeju Lekki, Lagos',
        description: '4 Bedroom Terrace Duplex at Ibeju Lekki, Lagos',
        country: 'Lagos State',
        bedrooms: '4',
        bathrooms: '',
        surface: '34890 sq ft',
        year: '',
        price: '95,000,000',
        type: 'House'
    },
    {
        id: 3,
        image: Image3,
        title: 'Plots of Land for sale',
        address: 'Eleworo via Ofada on Papalanto Road, Ogun State',
        description: 'Plots of Land for sale inside Mercyland Eleworo Estate',
        country: 'Lagos State',
        bedrooms: '',
        bathrooms: '',
        surface: '',
        year: '',
        price: '',
        type: 'Land'
    },
];