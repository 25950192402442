import React from 'react'
import {TiTimes} from 'react-icons/ti';
import ImpoImage from '../New assets/finding-the-right-real-estate-agent.jpg'

function Propertymanage({isOpenImpo, toggleImpoModal}) {
  return (
    <div>
      {isOpenImpo && (
      <div >
        <div onClick={toggleImpoModal} className='main'></div>
        <div className='close-bar bg-white fixed right-[8px] top-[100px] lg:right-[80px] lg:top-[150px] rounded-lg text-red-500 text-[25px]' onClick={toggleImpoModal}><TiTimes /></div>
        <div className='background h-[600px] w-[90%] lg:max-h-[500px] lg:max-w-[70%]'>
          <div className='modal-header bg-green-500'>
            <h3>The Importance Of Property Management Services In Nigeria</h3>
            
          </div>
          <div className='modal-body mt-[70px] p-4'>
            <div className='w-[80%] mx-auto border-gray-300 border-4 shadow-1 mb-[30px] mt-[90px]'>
              <img className='w-[100%]' src={ImpoImage} alt='' />
            </div>

            <p className='italic text-center text-[20px]'>
              “Real estate cannot be lost or stolen,<br/>

              Nor can it be carried away.<br/>

              Purchased with common sense,<br/>

              Paid for in full,<br/>

              And managed with reasonable care,<br/>

              It is about the safest investment in the world.”
              </p>

              <h5 className='text-center italic font-bold'> ---- FRANKLIN D. ROOSEVELT</h5>
              <p>
                Many people want to invest in real estate and they eventually succeed in building structures for their own benefit and for the benefit of those in need of property, either for rent or lease. Whichever way, people know that one of the best and most secured way of investing money is through real estate.

                Now, investing in real estate comes with a price and risks just like every other investment decision we make. We build structures and don't know how well to manage them.

                We just cannot seem to deny that owning and renting properties comes with a lot of stress. The constant watching over and answering tenants needs, the worrying about dealing with bad tenants and overall care and preservation of the property can really be daunting and time- consuming. Which is why we have Property Managers and Companies who devote their time and expertise to satisfy the needs and complaints of tenants and ensuring the good maintenance of the property on behalf of the Landlord.

                According to Investopedia, Property management is the daily oversight of residential, commercial or industrial real estate by a third-party contractor. The property manager takes responsibility for the day-to-day repairs and on-going maintenance, security and upkeep of properties.

                Below are some of the reasons why property management is important.<br/><br/>

              <ol>
                <li>&bull;  <span className='font-bold'>Good financial return on Real Estate:</span> a good property management company knows that investment in real estate is only profitable when there is good return on investment (ROI). As such, a property manager knows how to maximize returns on an investment property.</li><br/>
                <li>&bull; <span className='font-bold'>Marketing and planning:</span> one of the major tasks in the business of real estate is marketing. Understanding the competitiveness in the real estate market admonishes the property manager to make suitable marketing and advertising strategy on behalf of the investment owner seeing that he (the property manager) has a firm knowledge of the area. Sometimes, we see rental companies design marketing strategies by coming up with special promotion packages in order to maximize the occupancy and rental rates.<br/><br/>
                
                In exploring different market strategies, the property management company goes through the process of rigorous planning in order to take a sensible approach towards handling a client’s property and to ensure that the financial performance continues to be profiting for the management company and the landlord. </li><br/>
                <li>&bull;  <span className='font-bold'>Improving quality rental process:</span> As a good property Management Company, finding and keeping quality tenants can be somewhat frustrating and tedious. Hence, finding tenants that pay rent on time, minimize wear and tear and cause little or no problem is ideal. This is why hiring a property management company is advisable.<br/><br/>

                Speaking of rental process, sometimes we realize that as new property owners, we dislike being in bad terms with our tenants and end up making exceptions for them with the idea of trying to understand them. But we fail to realize how these can be a problem for our return on investment, and can also invite additional excuses from tenants. Like every other business venture, running a successful rental business means enforcing the rules even if such rules will lead to the eviction of a tenant who is not capable of paying rent. </li><br/>
                <li>&bull; <span className='font-bold'>Maintenance and Management of the Facility:</span> Maintenance and management of the property to avoid irreparable damages are all part of the property manager’s responsibilities. Finding a good and reliable handyman who can attend to repairs such as electrical repairs, plumbing, roofing, wall cracks, etc., can take a while which is why a property manager who really understands the market and maintain a good relationship with contractors is necessary.</li><br/>
                <li>&bull; <span className='font-bold'>Ability to understand and apply the law governing tenancy and leases:</span>  this is one of the most important reasons why you need to employ the expertise of a real estate or property management company. This is so because, the property management company will employ stringent means in order to ensure that the property is managed in accordance with the law especially in critical situations where a tenant needs to be evicted. The property manager in the course of his duty will have to be familiar with the laws governing contractual agreements as the tenancy agreement is an important agreement which is binding on all the parties. The Property Management Company also takes necessary measures to prevent frivolous lawsuits and to shield the landlord from liability if sued.</li>
              </ol>
              <br/><br/>
              

              The above is just some of many reasons why you should hire a Property Management Company. Having a good property manager for your property investment can help to attract and keep good tenants. Majority of landlords expect their property manager to receive rents on their behalf, find reliable tenants, maintain properties, deal with tenancy related disputes and sort out any issues at the end of a given tenancy period. It is however pertinent to note that the management of property structures exceeds just overseeing and maintenance of the property itself. The occupants are the most important element of property management.<br/><br/>

              With a Property Management Firm like Mercyland Estate Limited, you can be rest assured that your property will be effectively managed as we have been in the industry for a long while and have diligently managed to uphold a good portfolio relating with and on behalf of our clients in the real estate markets.<br/>

              Thank you for your time this week. Please do make sure you share this article with your family and friends. You can also send your enquiries /comments to info@mercylandgroup.com  or visit our office please visit our office<br/><br/>

              <span className='font-bold'>@ Mercyland Estate Limited</span><br/><br/>

              <span className='font-bold'>4th Floor Keystone Bank Building, 89 Allen Avenue, Ikeja, Lagos.</span><br/><br/>

              <span className='font-bold'>Or check out our website www.mercylandgroup.com.</span>
              
            </p>
          </div>
        </div>
      </div>)}
    </div>
  )
}

export default Propertymanage
