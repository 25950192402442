import React, { useEffect } from 'react';
import './App.css';
import './index.css';
//import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from './components/About';
import Gallery from './components/Gallery';
import Properties from './components/Properties';
import Contact from './components/Contact';
import Blog from './components/Blog';
import { BrowserRouter as Router, Switch, Routes, Route } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//import Navbar23 from './components/Navbar23';
//import Navbarnew from './components/Navbarnew';
import Navbarrewrite from './components/Navbarrewrite';
import Footer from './components/Footer';
//import { HouseContext } from './Properties/HouseContext';
import EducationConsult from './pages/EducationConsult';
import LegalConsult from './pages/LegalConsult';
import RealEstate from './pages/RealEstate';
import PropertyDetails from './Properties/PropertyDetails';
import Whatsapp from './helper/whatsapp';
import FeaturedPropertyDetails from './pages/FeaturedPropertyDetails'
import MercyLandDetails from './helper/MercyLandDetails';
import MercylandFarmdetails from './helper/MercylandFarmdetails';
import Scroll from './components/Scroll';





function App() {


  return (
    <div className="App">
        <Navbarrewrite />
       {/*<Navbarnew />*/}
        {/*<Navbar23 />*/}
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path="/ourestate" />
          <Route path='/properties' element={<Properties />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/education-consult' element={<EducationConsult />} />
          <Route path='/legal-consult' element={<LegalConsult />} />
          <Route path='/real-estate' element={<RealEstate />} />
          <Route path='/property/:id' element={<PropertyDetails />} />
          <Route path='/featuredproperty/:id' element={<FeaturedPropertyDetails />} />
          <Route path='/mercyland-details' element={<MercyLandDetails />} />
          <Route path='/mercylandfarm-details' element={<MercylandFarmdetails />} />
          
        </Routes>
        
        <Whatsapp/>
        <Footer />
        <Scroll />
    </div> 
  );
}

export default App;
