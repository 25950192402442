import React, {useEffect} from 'react'
import Image from '../New assets/New office stickers2.jpg'

function LegalConsult() {
  

  return (
      <div className='pt-[100px] pb-10 px-4'>
        <div className='grid grid-col lg:grid-cols-2 gap-5'>
          <div>
          <h1 className='text-center mb-3'>Legal Consult</h1>
          <p className='mb-10'>
          We are professionals at Adebiyi & Adebiyi (Legal Practitioners). We pride in providing legal advice and 
          strategies to individuals and organizations.
          </p>
          <p>
            Adebiyi & Adebiyi (Legal Practitioners) is an internationally recognised law firm based in Lagos, Nigeria. 
            Our wide range of expertise provides support for our clients in all areas of their business and private life. 
            Many of our lawyers are leaders in their field and our practice areas are highly ranked by the legal 
            directories. Whatever the situation we will achieve the best possible outcome.
          </p>

          <div className=''>
            <h5>Services</h5>
            <ul>
              <li>
                &#8226;  Solicitors & Advocacy
              </li>
              <li>
                &#8226;  Real Estate & Construction
              </li>
              <li>
                &#8226;  Business Registration
              </li>
              <li>
                &#8226;  Writing of Will & Testament
              </li>
              <li>
                &#8226;  Preparation of Deed of Assignment
              </li>
              <li>
                &#8226;  Wills, Trusts & Inheritances
              </li>
              <li>
                &#8226;  Land & Estate disputes
              </li>
              <li>
                &#8226;  Contractual Matters
              </li>
              <li>
                &#8226;  Filing and processing of Legal       titles - OF O, Governor's Consent etc
              </li>
            </ul>
          </div>
        </div>
        
        <div className='w-auto p-3 shadow-1 mb-5'>
          <img className='w-[100%]' src={Image} alt='' />
        </div>
        </div>
      

      
    </div>
  )
}

export default LegalConsult
