import React, { useState } from 'react'
import '../styles/About.css';
import Image from '../New assets/Update Files/About Us 1008x756.jpg'
import Profile1 from '../New assets/Pictures/Artboard 1.jpg'
import Profile2 from '../New assets/Pictures/Artboard 2.jpg'
//import Profile3 from '../New assets/Pictures/Artboard 3.jpg'
import Profile4 from '../New assets/Pictures/Artboard 4.jpg'
import Profile5 from '../New assets/Pictures/Artboard 5.jpg'
import Profile6 from '../New assets/Pictures/Artboard 6.jpg'
import Profile7 from '../New assets/Pictures/Artboard 7.jpg'
import Profile8 from '../New assets/Pictures/Artboard 8.jpg'
import Profile9 from '../New assets/Pictures/Artboard 9.jpg'
import AboutProfile from '../components/AboutProfile';
import AboutManagement from '../components/AboutManagement';

function About() {

  const [directors] = useState([
    {
      id: 1,
      name: "Michael Adebiyi",
      image: Profile6,
      office: 'Chief Executive Officer',
      status: "Michael Oluremi Adebiyi was born at Ajaawa, Hqts Ogo-Oluwa LG of Oyo State close to 3 decades ago. He was called to the Nigerian Bar in 1990. He worked as a junior counsel in two separate Law firms before he joined his colleagues to form the partnership of Odeh, Edokpolo and Adebiyi from where he pulled out to form his current partnership -Adebiyi & Adebiyi (Legal Practitioners) with his wife Florence in 1997. He is the Chairman & C.E.O of Mercyland Estates Limited. He is vast in all Property matters and all forms of documentation. Pastor Remi Adebiyi ( as he is fondly called) is also by the grace of God the Pastor in Charge of Lagos Province 24 of the Redeemed Christian Church of God. He has Pastored several parishes of RCCG including the National Headquarters of the Redeemed Christian Church of God ( RCCG The Throne of Grace) Ebutte Metta Lagos Nigeria. Michael Adebiyi is married to Florence Olabisi Adebiyi ( A Lawyer and also a Pastor of the RCCG). They are blessed with godly children and many spiritual Children to the glory of God.",
    },
    {
      id: 2,
      name: "Florence Adebiyi",
      image: Profile5,
      office: 'Managing Director',
      status: "Florence was called to the Nigerian Bar in 1991. She had a stint with a conglomerate as the Company Secretary and Legal Adviser before she resigned in 1999 to join her husband to form the partnership of Adebiyi & Adebiyi (Legal Practitioners). She is the Managing Director of Mercyland Estates Limited. She is a versatile Bargainer when it comesto business bargaining relationships between clients-subscribers, subscribers-client and general business bargaining.",
    },
    {
      id: 3,
      name: "Joanna Shorinde ",
      image: Profile7,
      office: 'UK-IRELAND CONSULTANT',
      status: "Joanna Shorinde holds a BSc (Hons) in Estate Management and Property Investment from London Southbank University, London U.K Joanna has over fifteen (15) years post qualification experience in real estate services professional, property management, building design, construction and real estate finance and information technology. She is a self-motivated, self-disciplined and team oriented person with a focus on achieving the goals of the organization and adding value to the community at large. Joanna has a strong communication skills and strong ability to respond to clients and the public in a culturally sensitive and empathic, non-judgemental respectful and diplomatic manner. She also has extensive experience working alongside multi-disciplined teams for residential and commercial properties and proven ability to offer the clients good project management support, delivering quality,customer focus and value for money services. She is able to deliver effective customer services through monitoring key performance indicators. Joanna has worked extensively with various employers, both in United Kingdom and North America including Bromley Valuation Office, Kent, United Kingdom, London Borough of Islington, London Borough of Croydon, United Kingdom, DMS Group, Hamilton, Ontario-Canada. Joanna has been a key player in regeneration initiatives, workingin partnership with Property Developers, Private Landlords and other property providers in the public, private and voluntary sectors. Joanna is a member of the Royal Institution of Chartered Surveyors, England U.K and has attended various Property Management Training, including Project Management and Development Training, London U.K, Housing First, Toronto, ON, Canada and Poverty & Schisms, Oakville, ON, Canada.",
    },
  ])

  const [profile] = useState([
    {
      name: "Chinyere Akarawolu",
      image: Profile4,
      office: 'General Manager',
      status: "Chinyere graduated from the Federal University of Technology Owerri, Imo State with a B. Tech. in Mathematics & Computer Science (Industrial Mathematics). She is also a PRINCE 2 certified Professional. She worked with Ruktam Global Solutions as a Secretary, also worked variously with Kingdom Realties. She is currently the General Manager of Adebiyi & Adebiyi (Legal Practitioners) & Mercyland Estates Limited",
    },
    {
      name: "Funbi 'Manuel",
      image: Profile1,
      office: 'Business Manager/Company Secretary',
      status: "Funbi has a first degree in Tourism Management from Afe babalola University and a Masters Degree in Transport planning and management from the University of Lagos. She has worked for over 7 years in the Travel industry/ Real Estate Industry. She's currently the Business Manager / Company Secretary of Mercyland Estate Limited.",
    },
    {
      name: 'Amb. Oluremi Enigbokan',
      image: Profile2,
      office: 'Marketing/Media Manager',
      status: 'Oluremi graduated from Osun State College of Technology, Esa-Oke in Banking and Finance. He worked in one of the leading banks in Nigeria (in Sales & Marketing department). He is also trained by CDSEI, German Mission in Nigeria, Acchub and well vast in Media/Digital Marketing, Graphics Design, Photography, Digital Printing, Web Design to mention few. He is currently the Marketing/Media Manager of MercyLand Estates Limited.',
    },
    {
      name: "Akinloye Reuben Orosola",
      image: Profile9,
      office: 'Property Manager',
      status: "Akinloye Reuben Orosola, Property Manager graduated with HND in Statistics at The Federal Polytechnic Ado, Ekiti State. He worked with Solrenson  Engineering Service as Loss Adjuster/Account officer, also worked as Data Analyst for final year students in various institution. He is skillful in people management. He is currently the Property Manager of Mercyland Estates Limited.",
    },
    {
      name: "Ayokunle Olufade",
      image: Profile8,
      office: 'Legal Consultant',
      status: "Ayokunle Olufade is a holder of a Bachelor of Laws (LL. B) from Adekunle Ajasin University, Ondo State, Nigeria, and was called to the Nigerian Bar upon completion of Nigerian Law School. He has a penchant for Litigation & Dispute Resolution that cuts across different areas of laws and courts. He has shown versatility in Corporate and Commercial practice, particularly in Business formation and Development Advisory, and has a special stint on IP/IT Laws where he majored in his Master's Degree obtained from the University of Ilorin.",
    },
    
  ])

  return (
    <div className='pb-[50px] pt-[100px] bg-[#F7EBFB]'>
      <div className='container'>
        <div className='lg:flex flex-row gap-10 mb-[80px] sm:justify-center'>
          <div>
            <h1 className='text-center'>About Us</h1>
            <h5 className='bg-green-500 py-2 px-3 rounded-full text-white shadow-1'>Who We Are</h5>
            <p>
              Mercyland Estates Limited was founded to be a company for everyone who dares to become a property 
              owner in any part of Nigeria and indeed the world. Our organization brings 15+ years of experience 
              committed to providing a way for those who want to become land/property owners without hassels. We 
              have made over 1,500 people proud land and property owners! Mercyland Estates Limited is in business 
              purposely to play a role in making it convenient for as many as possible to have easy access to secured, 
              Government approved, relatively cheap plots/acres of land. When it comes to Mercyland Estates 
              Limited services, our integrity, transparency, attention to detail, and professional approach set us apart. 
              We walk with you every step along your journey to become a land/property owner.
            </p>
          </div>
          <div className='w-auto p-1 bg-white shadow-1 rounded-[20px] sm:bg-green-600'><img className='w-[100%] lg:w-[5000px] rounded-lg' src={Image} alt='' /></div>
        </div>

        <div>
          <h5 className='bg-green-500 py-2 px-3 rounded-full text-white shadow-1'>Our Mission</h5>
          <p>
            Our mission is to be the best real estate company in Africa. We will serve clients with excellence, 
            professionalism & honesty and will work hard to help every single customer achieve their dreams. We 
            shall also make buying and selling of real estate as cost effective as possible while maintaining the 
            highest level of service delivery.
          </p>
        </div>
        <div>
           <h5 className='bg-green-500 py-2 px-3 rounded-full text-white shadow-1'>Our Vision</h5>
          <p>
            To achieve the highest possible standard in the real estate world.
          </p>
        </div>

        <div>
          <h5 className='bg-green-500 py-2 px-3 rounded-full text-white shadow-1'>Company Profile</h5>
          <p>Founded in 2007 and headquartered in Ikeja, Lagos, Mercyland Estates Limited renders all aspect of 
            property services which include Sales, Letting, Leasing, Construction, property consultancy, project 
            management among other services.
          </p>
        </div>


        <div className='py-20 flex flex-col justify-center gap-10 md:justify-center'>
          <h1 className='text-center'>Profile Update</h1>
          <h3 className='text-center px-3 py-2 bg-green-500 text-white'>BOARD OF DIRECTOR</h3>
          <div className='grid grid-col lg:grid-cols-2 items-center gap-10'>
              {directors.map((director) => {
                return(
                  <AboutManagement 
                   name={director.name}
                   image={director.image}
                   office={director.office}
                   status={director.status}
                   id={director.id}
                   />
                )
              })}
            </div>
          
          <h3 className='text-center px-3 py-2 bg-green-500 text-white'>MANAGEMENT TEAM</h3>
          <div className='grid grid-col lg:grid-cols-2 items-center gap-10'>
              {profile.map((about) => {
                return(
                  <AboutProfile 
                   name={about.name}
                   image={about.image}
                   office={about.office}
                   status={about.status}
                   />
                )
              })}
            </div>
        </div>
      </div>
    </div>
  )
}

export default About
