import React from 'react';
import { BiBed, BiBath, BiArea } from 'react-icons/bi';
import '../styles/Properties.css';


const House = ({ house }) => {
  const { image, type, country, address, bedrooms, bathrooms, surface, price } = house;
  return (
    <div className='houseList bg-white shadow-1 p-4 rounded-lg w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition mb-10 h-[550px]'>
      <div className='house--image rounded-tl-[100px] rounded-br-[100px]'>
        <div className='house--imagechild'>
          <img className='mb-8 rounded-tl-[100px] rounded-br-[100px]' src={image} alt='' />
        </div>
      </div>
      <div className='mb-4 flex gap-x-2 text-lg'>
        <div className='bg-green-500 rounded-lg text-white px-3 py-1'>{type}</div>
        <div className='bg-[#A943C5] rounded-lg text-white px-3 py-1'>{country}</div>
      </div>
      <div className='address text-lg font-semibold max-w-[260px] text-black'>{address}</div>
      <div className='flex gap-x-4 my-4'>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px]'>
            <BiBed />
          </div>
          <div>{bedrooms}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px]'>
            <BiBath />
          </div>
          <div>{bathrooms}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px]'>
            <BiArea />
          </div>
          <div>{surface}</div>
        </div>
      </div>
      <div className='text-lg font-semibold text-green-500 mb-4'>{price}</div>
    </div>   
  );
};

export default House;
