import React from 'react';
import Image from '../New assets/Update Files/property top page 705x646.jpg';
import Search from '../Properties/Search';
import '../styles/Properties.css';

const Banner = () => {
  return <section className='h-full max-h-[640px]  mt-10 pt-[80px]'>
    <div className='banner flex flex-col lg:flex-row'>
      <div className='bannerTitle lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:text-left text-center justify-center flex-l px-4 lg:px-0'>
        <h1 className='text--rent text-4xl text-[#740195] lg:text-[58px] font-semibold leading-none mb-6'><span className='text-green-500'>Rent and Buy</span> Your Dream Properties With Us.</h1>
        <p className='text--title max-w-[480px] mb-0 sm:mb-6 text-lg'>
          Come, Lets bring your dream home and properties to reality. Mercyland Properties got you covered in all kind of home and properties.
        </p>
      </div>
      <div className='picture--head hidden flex-l lg:flex justify-end items-end mr-10'>
        <img src={Image} alt='' />
      </div>
    </div>
    <Search />
  </section>;
};

export default Banner;
