import React, { useState } from 'react'
import '../styles/Gallery.css';
import {TiTimes} from 'react-icons/ti';

function Galleries(props) {

  const [bold, setBold] = useState(false);

  const toggleBold = () => {
    setBold(!bold)
  }

  if(bold){
    document.body.classList.add('activey')
  }else{
    document.body.classList.remove('activey')
  }

  return (
    <div className='activey'>
      <div className='picture  hover:cursor-pointer rounded-tl-[80px] rounded-br-[80px] '>
        <div className='picture--image'>
          <img onClick={toggleBold} className=' rounded-tl-[80px] rounded-br-[80px] border-4 border-white' src={props.picture} alt='' />
        </div>
      </div>

      {bold &&
      <div className='main'>
        <div onClick={toggleBold} className='overlaypic'></div>
          <img className='picback h-auto lg:max-h-[80%]' src={props.picture} alt='' />
          <div onClick={toggleBold} className='close'><TiTimes /></div>
      </div>}
    </div>
  )
}

export default Galleries
