import React from 'react'
import WhatsApp from '@mui/icons-material/WhatsApp';
import '../styles/whatsapp.css'

function whatsapp() {
  return (
    <div>
      <a href='https://wa.link/0sfdbi' target='blank'><button className='whatsapp'><WhatsApp /></button></a>
    </div>
  )
}


export default whatsapp;
