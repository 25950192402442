import React, {useState} from 'react';
import Houses from '../helper/Houses';
import { Link } from 'react-router-dom';
import '../styles/Houses.css';
import Image1 from '../New assets/Flyers/Featured Property 1 inner.jpg'
import Image2 from '../New assets/Flyers/FEATURED PROPERTY 2 Outer.jpg'
import Image3 from '../New assets/Flyers/featured properties 3 outer.jpg'


 function Featuredprop() {
    const [house] = useState([
        {
            id: 1,
            price: '2.5 million',
            image: Image1,
            title: '4 Bedroom Terrace Duplex',
            address: 'Lekki Gardens behind Lagos Business School, Ajah, Lekki, Lagos',
            description: '4 Bedroom Terrace Duplex at Lekki Gardens behind Lagos Business School, Ajah, Lekki, Lagos',
        },
        {
            id: 2,
            price: '2 million',
            image: Image2,
            title: '4 Bedroom Terrace Duplex',
            address: 'Ibeju Lekki, Lagos',
            description: '4 Bedroom Terrace Duplex at Ibeju Lekki, Lagos',
        },
        {
            id: 3,
            price: '',
            image: Image3,
            title: 'Plots of Land for sale',
            address: 'Eleworo via Ofada on Papalanto Road, Ogun State',
            description: 'Plots of Land for sale inside Mercyland Eleworo Estate',
        },
    ]);

    

  return (
        <div className='bg-slate-200 py-20'>
            <h1 className='text-center mb-10'>Featured Properties</h1>
            <div align='center' className='featured-house grid lg:grid-cols-3 md:grid-cols-2 gap-y-4 mx-auto'>
                {house.map((houses, index) => {
                    return(
                        <Link to={`/featuredproperty/${houses.id}`} key={index}>
                            <Houses
                                id={houses.id}
                                image={houses.image} 
                                title={houses.title} 
                                address={houses.address}
                                price={houses.price}
                                description={houses.description}
                            />
                        </Link>
                    )
                })}
            </div>
        </div>
  )
}

export default Featuredprop;
