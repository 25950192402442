import React from 'react'
import '../styles/Whychoose.css'


function Whychoose() {
  return (    
    <div className='bg-[#CDFACC] py-20'>
      <h1 className='text-center mb-5'>Why Choose Mercyland</h1>
    
      <div className='container grid grid-col lg:grid-cols-2 gap-20 shrink-none'>

        <div className=''>
          <h3 className='lg:w-[650px]'>Quality</h3>
          <div className='bg-green-500 px-4 text-center text-white rounded-full h-5'>
          <p>100%</p>
          </div>
        </div>

        <div className=''>
          <h3 className='lg:w-[650px] '>Cost Effective</h3>
          <div className='bg-green-500 px-4 text-center text-white rounded-full h-5'>
          <p>100%</p>
          </div>
        </div>

        <div className=''>
          <h3 className='lg:w-[650px] '>Commitment</h3>
          <div className='bg-green-500 px-4 text-center text-white rounded-full h-5'>
          <p>100%</p>
          </div>
        </div>

        <div className=''>
          <h3 className='lg:w-[650px] '>Standard of Excellency</h3>
          <div className='bg-green-500 px-4 text-center text-white rounded-full h-5'>
          <p>100%</p>
          </div>
        </div>
      </div>
    </div>
    
    
  );
}

export default Whychoose
