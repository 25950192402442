import React, { useState } from 'react';
import { housesData } from '../data';
import { useParams } from 'react-router-dom';
import { BiBed, BiBath, BiArea } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import PaystackPop from '@paystack/inline-js';
import '../styles/Gallery.css';
import {TiTimes} from 'react-icons/ti';


function PropertyDetails() {
    const { id } = useParams();

    const house = housesData.find((house) => {
        return house.id === parseInt(id);
    });

    const price = Number(house.price.replace(/,/g, ''));

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [amount, setAmount] = useState(price)
    const [details, setDetails] = useState('')
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: ''
    })
    

    console.log(Number(house.price.replace(/,/g, '')));

    

    const buy = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!name.trim()) {
          newErrors.name = 'Name is required';
        }  if(!email.trim()) {
            newErrors.email = 'Email is required'
        }
         if(!phone.trim()) {
            newErrors.phone = 'Phone is required'
        }
      
        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            // Proceed with payment
            const paystack = new PaystackPop()
        paystack.newTransaction({
            key:'pk_test_e89df24fb3e3755eb7a8e20f49dcbb30082f674f',
            amount: amount*100,
            name,
            email,
            phone,
            details,

            

            onSuccess(transaction){
                let message= `Payment Complete! Reference ${transaction.reference}`
                alert(message)

                setName('')
                setEmail('')
                setPhone('')
                setAmount('')
                setDetails('')
            },  
            onCancel(){
                alert('You have Canceled the transaction')
            },          
        })
          } else {
            // Handle validation errors
            console.log('Form validation failed');
          }

        
    }

        const [bold, setBold] = useState(false);

        const toggleBold = () => {
            setBold(!bold)
        }

        if(bold){
            document.body.classList.add('active')
        }else{
            document.body.classList.remove('active')
        }
    
  return (
    <section className='pt-[60px] active'>
      <div className='container mx-auto min-h-[800px]   mb-14 mt-10 '>
            <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between'>
                <div>
                    <h2 className='text-2xl font-semibold'>{house.name}</h2>
                    <h3 className='text-lg mb-4'>{house.address}</h3>
                </div>
                <div className='mb-4 lg:mb-0 flex gap-x-2 text-sm'> 
                    <div className='bg-[#af6ec2] text-white px-3 rounded-lg py-1'>{house.type}</div>
                    <div className='bg-green-500 text-white px-3 rounded-lg py-1'>{house.country}</div>
                </div>
                <div className='text-3xl font-semibold text-[#A943C5]'>N {house.price}</div>
            </div>
            <div className='flex flex-col items-start gap-8 lg:flex-row'>
                <div className='max-w-[768px]'>
                    <div className='mb-8 lg:w-[600px]'>
                        <img className='picturepage' onClick={toggleBold} src={house.imageLg} alt='' />
                    </div>

                    {bold &&
                        <div className='main'>
                            <div onClick={toggleBold} className='overlaypic'></div>
                            <img className='picback' src={house.imageLg} alt='' />
                            <div onClick={toggleBold} className='close'><TiTimes /></div>
                        </div>}

                    <div className='flex gap-x-6 text-[#A943C5] mb-6'>
                        <div className='flex gap-x-2 items-center'>
                            <BiBed className='text-2xl' />
                            <div>{house.bedrooms}</div>
                        </div>
                        <div className='flex gap-x-2 items-center'>
                            <BiBath className='text-2xl' />
                            <div>{house.bathrooms}</div>
                        </div>
                        <div className='flex gap-x-2 items-center'>
                            <BiArea className='text-2xl' />
                            <div>{house.surface}</div>
                        </div>
                    </div>
                    <div className='lg:max-w-[625px]'>{house.description}</div>
                </div>
                <div className='flex-1 bg-white w-full mb-8 border border-gray-300 rounded-lg px-6 py-8'>
                    {/*<div className='flex items-center gap-x-4 mb-8'>
                        <div className='w-20 h-20 p=1 border boder-gray-300 rounded-full'>
                            <img src={house.agent.image} alt='' />
                        </div>
                        <div>
                            <div className='font-bold text-lg'>{house.agent.name}</div>
                            <Link className='text-yellow-700 text-sm'>
                                View Listings
                            </Link>
                        </div>
  </div>*/}
                    <form className='flex flex-col gap-y-4'>
                        {errors.name && <p className='text-red-500 m-0'>{errors.name}</p>}
                        <input value={name} required onChange={(e) => {setName(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, name: '' }));}} className={`border border-gray-300 focus:border-green-600 outline-none rounded w-full px-4 h-14 text-sm ${errors.name && 'border-red-500'}`} type='text' placeholder='Full Name*' id='name' />
                        {errors.email && <p className='text-red-500'>{errors.email}</p>}
                        <input value={email} onChange={(e) => {setEmail(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, email: '' }));}} className={`border border-gray-500 focus:border-green-600 outline-none rounded w-full px-4 h-14 text-sm ${errors.name && 'border-red-500'}`} type='text' placeholder='Email Address*' id='email-address' />
                        {errors.phone && <p className='text-red-500'>{errors.phone}</p>}
                        <input value={phone} onChange={(e) => {setPhone(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));}} className={`border border-gray-500 focus:border-green-600 outline-none rounded w-full px-4 h-14 text-sm ${errors.name && 'border-red-500'}`} type='text' placeholder='Phone' id='phone' />
                       <input value={amount} readOnly onChange={(e) => {setAmount(e.target.value)}} 
                        className='border border-gray-500 focus:border-green-600 outline-none rounded w-full px-4 h-14 text-sm'  id='amount' />
                        <textarea onChange={(e) => {setDetails(e.target.value)}} className='focus:outline-none border border-gray-500 0utline-none esize-none rounded w-full p-4 h-36 text-gray-400' placeholder='Hello, I am interested in (please indicate if you are buying or renting)'  id='textarea'></textarea>
                        <div className='flex gap-x-2'>
                            <button onClick={buy} className='bg-[#A943C5] hover:bg-[#9159A2] text-white rounded p-3 text-lg w-full transition'>Buy</button>
                            <button type='submit' onClick={buy} className='border border-green-700 text-white hover:border-green-500 hover:text-white bg-green-500 rounded p-3 text-lg w-full transition hover:bg-green-500'>Rent</button>
                        </div>
                    </form>
                </div>
            </div>
      </div>
    </section>
  )
}

export default PropertyDetails
