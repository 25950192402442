import React, {useState} from 'react';
import Legaldocumentation from '../Blog/Legal-documentation';
import Propertymanage from '../Blog/Property-manage';
import Quickguide from '../Blog/Quick-guide';
import Advantages from '../Blog/Advantages';
import { FcDocument } from 'react-icons/fc';
import { GrUserManager } from 'react-icons/gr';
import { RiGuideFill } from 'react-icons/ri';
import { TbWritingSign } from 'react-icons/tb';
import '../styles/Blog.css'


function Blog() {

  const [legalModal, setLegalModal] = useState(false);
  const [impoModal, setImpoModal] = useState(false);
  const [quickModal, setQuickModal] = useState(false);
  const [advanModal, setAdvanModal] = useState(false);

    const toggleLegalModal = () => {
      setLegalModal(prevLegalModal => !prevLegalModal);
    };
    
    const toggleImpoModal = () => {
      setImpoModal(prevImpoModal => !prevImpoModal);
    };
    
    const toggleQuickModal = () => {
      setQuickModal(prevQuickModal => !prevQuickModal);
    };

    const toggleAdvanModal = () => {
      setAdvanModal(prevAdvanModal => !prevAdvanModal);
    };
    
    if(legalModal){
      document.body.classList.add('actives')
     }else if(impoModal){
      document.body.classList.add('actives')
     }else if(quickModal){
      document.body.classList.add('actives')
     }else if(advanModal){
      document.body.classList.add('actives')
     } else{
      document.body.classList.remove('actives')
     }
    
  return (
    <section className='actives py-[100px]'>
      <div className='grid-card grid grid-col lg:grid-cols-3 md:grid-cols-2 gap-4 mx-auto container'>
        <div className='icons bg-slate-200 py-20 px-5 rounded-3xl flex flex-col gap-3 justify-center items-center cursor-pointer'>
          <FcDocument className='text-[60px]'/>
          <h4 className='text-center'>The Importance Of Legal Documentation In Real Estate Transactions</h4>
          <button onClick={toggleLegalModal} className='bg-green-500 mx-auto py-1 px-3 text-white text-[20px] rounded-lg'>Read More</button>
          <Legaldocumentation isOpen={legalModal} toggleLegalModal={toggleLegalModal} />
        </div>

        
        <div className='icons bg-slate-200 py-20 px-5 rounded-3xl flex flex-col gap-3 justify-center items-center cursor-pointer'>
          <GrUserManager className='text-[60px]'/>
          <h4 className='text-center'>The Importance Of Property Management Services In Nigeria</h4>
          <button onClick={toggleImpoModal} className='bg-green-500 mx-auto py-1 px-3 text-white text-[20px] rounded-lg'>Read More</button>
          <Propertymanage isOpenImpo={impoModal} toggleImpoModal={toggleImpoModal} />
        </div>
        
        <div className='icons bg-slate-200 py-20 px-5 rounded-3xl flex flex-col gap-3 justify-center items-center cursor-pointer'>
          <RiGuideFill className='text-[60px]'/>
          <h4 className='text-center'>Quick Guide To Buying a House/Property In Lagos.</h4>
          <button onClick={toggleQuickModal} className='bg-green-500 mx-auto py-1 px-3 text-white text-[20px] rounded-lg'>Read More</button>
          <Quickguide isOpenQuick={quickModal} toggleQuickModal={toggleQuickModal} />
        </div>

        <div className='icons bg-slate-200 py-20 px-5 rounded-3xl flex flex-col gap-3 justify-center items-center cursor-pointer'>
          <TbWritingSign className='text-[60px]'/>
          <h4 className='text-center'>Advantages Of Making a Will...</h4>
          <button onClick={toggleAdvanModal} className='bg-green-500 mx-auto py-1 px-3 text-white text-[20px] rounded-lg'>Read More</button>
          <Advantages isOpenAdvan={advanModal} toggleAdvanModal={toggleAdvanModal} />
        </div>
      </div>
    </section>
  )
}

export default Blog
