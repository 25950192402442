import React, { useState } from 'react'
import Galleries from '../components/Galleries';
import Galleriesvid from '../components/Galleriesvid';
import '../styles/Gallery.css';
import Estate1A from '../New assets/Update Files/mercyland 1 asjj.jpg'
import Estate1B from '../New assets/Update Files/mercyland 1 dhdjdd.jpg'
import Estate1C from '../New assets/Update Files/mercyland 1 sgsd.jpg'
import Estate1D from '../New assets/Update Files/20230924_161451.jpg'
import Estate2C from '../New assets/Update Files/mercyland 2 aad.jpg'
import Estate2A from '../New assets/Update Files/Gallery Mercyland 2 a.jpg'
import Estate2B from '../New assets/Update Files/Gallery Mercyland 2 b.jpg'
import Estate2D from '../New assets/Update Files/Gallery Mercyland 2 d.jpg'
import Estate2E from '../New assets/Update Files/Gallery Mercyland 2 E.jpg'
import Delight1 from '../New assets/Update Files/gallery mercyland delight aa.jpg'
import Delight2 from '../New assets/Update Files/gallery mercyland delight bdd.jpg'
import Delight3 from '../New assets/Update Files/Gallery Mercyland Delight c.jpg'
import Delight4 from '../New assets/Update Files/Gallery Mercyland Delight a.jpg'
import Delight5 from '../New assets/Update Files/Gallery mercyland delight dddd.jpg'
import Idera1 from '../New assets/Update Files/Gallery Idera Estate a.jpg'
import Idera2 from '../New assets/Update Files/Gallery Idera Estate aaa.jpg'
import Idera3 from '../New assets/Update Files/Gallery Idera Estate abb.jpg'
import Idera4 from '../New assets/Update Files/Gallery Idera Estate b.jpg'
import Idera5 from '../New assets/Update Files/Gallery Idera Estate c.jpg'
import Idera6 from '../New assets/Update Files/Gallery Idera Estate d.jpg'
import NewOffice1 from '../New assets/Update Files/Gallery New office a.jpg'
import NewOffice2 from '../New assets/Update Files/Gallery New office b.jpg'
import NewOffice3 from '../New assets/Update Files/Gallery New office c.jpg'
import NewOffice4 from '../New assets/Update Files/Gallery New office d.jpg'
import NewOffice5 from '../New assets/Update Files/Gallery New office E.jpg'
import PhotoSplash1 from '../New assets/Update Files/photo splash aaa.jpg'
import PhotoSplash2 from '../New assets/Update Files/photo splash ahaha.jpg'
import PhotoSplash3 from '../New assets/Update Files/photo splash ahass.jpg'
import PhotoSplash4 from '../New assets/Update Files/photo splash sadd.jpg'
import PhotoSplash5 from '../New assets/Update Files/photo splash.jpg'
import Video1 from '../New assets/Mercyland_Delight_Estate.mp4';
import Video2 from '../New assets/New Property/Video Advert.mp4';
import Video3 from '../New assets/New Property/New video Ads.mp4';


function Gallery() {

  const [estate1] = useState([Estate1A, Estate1B, Estate1C, Estate1D]);
  const [estate2] = useState([Estate2A, Estate2B, Estate2C, Estate2D, Estate2E]);
  const [delight] = useState([Delight1, Delight2, Delight3, Delight4, Delight5]);
  const [idera] = useState([Idera1, Idera2, Idera3, Idera4, Idera5, Idera6]);
  const [newoffice] = useState([NewOffice1, NewOffice2, NewOffice3, NewOffice4, NewOffice5]);
  const [photosplash] = useState([PhotoSplash1, PhotoSplash2, PhotoSplash3, PhotoSplash4, PhotoSplash5]);

  const [video] = useState([Video1,Video3, Video2]);

  return (
    <div className='bg-green-100'>
      <div className='fluid py-10 px-2  pt-[100px]'>
        <div>
          <h1 className='text-center'>Pictures</h1>
            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>MercyLand 1 Estate</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-4 mb-4 lg:px-4'>
                {estate1.map((estate1s) => {
                  return(
                    <Galleries picture={estate1s}/>
                  )
                })}
              </div> 
            </div>

            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>Mercyland 2 Estate</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 py-4 mb-4 lg:px-4'>
                {estate2.map((estate2s) => {
                  return(
                    <Galleries picture={estate2s}/>
                  )
                })}
              </div> 
            </div>

            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>Mercyland Delight Estate</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 py-4 mb-4 lg:px-4'>
                {delight.map((delights) => {
                  return(
                    <Galleries picture={delights}/>
                  )
                })}
              </div> 
            </div>

            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>Mercyland Idera Estate</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 py-4 mb-4 lg:px-4'>
                {idera.map((ideras) => {
                  return(
                    <Galleries picture={ideras}/>
                  )
                })}
              </div> 
            </div>

            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>New Branch Office</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 py-4 mb-4 lg:px-4'>
                {newoffice.map((newoffices) => {
                  return(
                    <Galleries picture={newoffices}/>
                  )
                })}
              </div> 
            </div>

            <div className='bg-[#af6ec2] py-10 container lg:px-5 rounded-[10px] lg:rounded-[30px] text-white mb-20'>
              <h3 className='text-center text-white'>Photo Splash</h3>
              <div className='gallery-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 py-4 mb-4 lg:px-4'>
                {photosplash.map((photosplashs) => {
                  return(
                    <Galleries picture={photosplashs}/>
                  )
                })}
              </div> 
            </div>
        </div>
        
        <div className='bg-[#af6ec2] py-20 container rounded-[10px] text-white mb-20'>
          <h1 className='text-center text-white'>Videos</h1>
          <div align='center' className='py-4 grid lg:grid-cols-2 gap-4'>
            {video.map((videos) => {
              return(
                <Galleriesvid video={videos} />
              )
            })}
          </div>
        </div>
          
      </div>
    </div>
    
  )
}

export default Gallery
