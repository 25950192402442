import React, { useState, useContext } from 'react';
import { RiHome5Line, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Menu } from '@headlessui/react';
import { HouseContext } from './HouseContext';

const PropertyDropdown = () => {
  const { property, setProperty, properties } = useContext(HouseContext);


  const [isOpen, setIsOpen] = useState(false);

  return <Menu as='div' className='dropdown relative w-full lg:max-w-[296px] cursor-pointer;'>
    <Menu.Button 
      onClick={() => setIsOpen(!isOpen)}
      className='w-full text-left flex h-[64px] items-center px-[18px] border rounded-lg'
      >
        <RiHome5Line className='dropdown-icon-primary text-2xl mr-[18px] text-green-500' />
        <div>
          <div className='text-[15px] font-medium leading-tight'>{property}</div>
          <div className='text-[13px]'>Select property</div>   
        </div>   
        {isOpen ? (
            <RiArrowUpSLine className='text-2xl text-green-500 ml-auto' />
            ) : (
              <RiArrowDownSLine className='text-2xl text-green-500 ml-auto' />
            )
          }     
    </Menu.Button>

    <Menu.Items className='px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;'>
      {properties.map((property, index) => {
        return (
          <Menu.Item
            onClick={() => setProperty(property)}
            className='cursor-pointer hover:text-green-700 transition'
            as='li'
            key={index}
          >
            {property}
          </Menu.Item>
        )
      })}
    </Menu.Items>
  </Menu>;
};

export default PropertyDropdown;
