import React from 'react'
import {TiTimes} from 'react-icons/ti';
import ImpoImage from '../New assets/jan-20-real-estate-top-pic.jpg';


function Quickguide({isOpenQuick, toggleQuickModal}) {
  return (
    <div>
    {isOpenQuick && (
    <div >
      <div onClick={toggleQuickModal} className='main'></div>
      <div className='close-bar bg-white fixed right-[8px] top-[100px] lg:right-[80px] lg:top-[150px] rounded-lg text-red-500 text-[25px]' onClick={toggleQuickModal}><TiTimes /></div>
      <div className='background h-[600px] w-[90%] lg:max-h-[500px] lg:max-w-[70%]'>
        <div className='modal-header bg-green-500'>
          <h3>The Importance Of Property Management Services In Nigeria</h3>
          
        </div>
        <div className='modal-body mt-[70px] p-4'>
          <div className='w-[80%] mx-auto border-gray-300 border-4 shadow-1 mb-[30px] mt-[90px]'>
            <img className='w-[100%]' src={ImpoImage} alt='' />
          </div>
            <p>
              I guess we all get to a point in our lives where we get tired of living under someone’s roof and decide it’s time to make a home for ourselves. To most people, buying a house or investing in real estate is one of the most reasonable financial decisions to make in a lifetime. In essence whenever we are ready to take that step either to buy a house, land for residential purposes or


              you rather want to invest in real estate, it is therefore wise to apply due diligence in probing the authenticity and validity of the property before making any payment.

              Truth is, buying a land or house in Nigeria, especially in Lagos, comes with a lot of risk or mistakes. It is one of the easiest ways to get defrauded. Hence the need to highlight some factors, guidelines and list of things to consider when buying a property.<br/><br/>

            <ol>
              <li>&bull;  <span className='font-bold'>Know what you want and draw a budget:</span> As basic as this may sound, it is in fact the first basic thing to do before attempting to search for a land or house to buy. This helps you work with a budget also considering other little expenses that may come with the purchase of a property. Knowing what you want also determines the purpose for which the land will be used.</li><br/>
              <li>&bull; <span className='font-bold'> Conduct a Search:</span> before buying a property, it is important that you carry out some investigations so as to ensure that the vendor or the person selling the property is the rightful owner of the property. To ensure that the vendor possesses a legal title t o sell the property, you must first conduct a search of the property. You can do this by visiting the Land Registry of the State and also make sure to visit the property in person and conduct a physical investigation of the property before purchase.</li><br/>
              <li>&bull;  <span className='font-bold'>The Size of the Land:</span> Another thing to consider when buying a land or property is the size of the land to make sure it is accurate with what the land owner tells you. One way to check the accuracy of the size of a land is to involve the expertise of a surveyor.</li><br/>
              <li>&bull; <span className='font-bold'>Location and Accessibility of the Land:</span> the location of a land or house plays a vital role as the location in fact determines the cost of the property. Considering the location of the property also sets our mind to consider the major purpose of the property we intend to purchase whether for residential or commercial purposes.</li><br/>
              <li>&bull; <span className='font-bold'> Demand and Verify copies of the Land Documents:</span> in the course of buying a property in Lagos, it is important to know who owns the land to avoid all sorts of messy

              circumstances in the nearest future. Here’s a list of documents to ask for when buying a property in Lagos or in other parts of Nigeria.
              <ol>
                <li>(a)          Survey Plan</li>
                <li>(b)      Certificate of Occupancy</li>
                <li>(c)       Deed of assignment</li>
                <li>(d)      Governor’s consent</li>
                <li>(e)       Tax clearance certificate</li>
              </ol>
              Never make payment for any property without the provision of these vital documents and always insist on viewing the original copies of these documents to avoid stories that touch.
              </li>
              <li>&bull; <span className='font-bold'> Make payment to the owner:</span> after conducting a search and you are satisfied with the location of the property, the price as well as the authenticity of the documents, you can then make payment to the owner of the property. Make sure not to make any payment to an agent, secretary or any other person who is not the owner of the property and do the needful by documenting and requesting a Purchase Receipt.</li><br/>
              <li>&bull; <span className='font-bold'>Finally, take Physical Possession of the Property and make sure to file necessary documentation with the government.</span>

              Other issues to look out for and consider when purchasing a land or house in Lagos are:
                <ol>
                  <li>&bull; Boundaries of the property</li>
                  <li>&bull; Disputes over the property</li>
                  <li>&bull; Guarantees in respect of the property</li>
                  <li>&bull; Services supplied on the property</li>
                  <li>&bull; Planning schemes of the property</li>
                  <li>&bull; Insurance provisions and policies</li>
                  <li>&bull; Reversionary title or interest</li>
                </ol>      

              The process of buying a property in Lagos can be very demanding and time consuming. So many people end up in the hands of land grabbers and fraudsters while some others end up buying lands in areas that are Government reserved. In order to save yourself from falling victim to such a scam and avoid making mistakes that will cost you more than the purchase price in the nearest future, why don’t you engage the services of a lawyer to conduct due diligence in respect of the house to be sold.</li><br/>
            </ol>
            <br/><br/>
            

            Thank you for your time this week. Please make sure you share this article with your family and friends. You can  a and or visit our office.
            <br/><br/>
            <span className='font-bold'>Adebiyi & Adebiyi (Legal Practitioners) 4th Floor, 89, Allen Avenue, Ikeja, Lagos 08038949316</span>
            
          </p>
        </div>
      </div>
    </div>)}
  </div>
  )
}

export default Quickguide
