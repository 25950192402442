import React, { useState, useEffect } from 'react' 
import image1 from '../New assets/Slide-A.jpg'
import image2 from '../New assets/Slide-B.jpg'
import image3 from '../New assets/Slide-C.jpg'
import image4 from '../New assets/Slide-D.jpg'
import image5 from '../New assets/Slide-F.jpg'
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs'
import {RxDotFilled} from 'react-icons/rx'
import artimage1 from '../New assets/Mobile-A.jpg'
import artimage2 from '../New assets/Mobile-B.jpg'
import artimage3 from '../New assets/Mobile-C.jpg'
import artimage4 from '../New assets/Mobile-D.jpg'
import artimage5 from '../New assets/Mobile-F.jpg'
//import Search from '../Properties/Search';
import '../styles/HomeSlide.css'



function HomeSlider() {
    const slides = [
        {
            image: image5
        },
        {
            image: image1
        },
        {
            image: image2
        },
        
        {
            image: image3
        },
        {
            image: image4
        },
        
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
        
    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }


    const slidesMobile = [
        {
            image: artimage5
        },
        {
            image: artimage1
        },
        {
            image: artimage2
        },
        {
            image: artimage3
        },
        {
            image: artimage4
        },
        
    ];

    const [currentIndexMobile, setCurrentIndexMobile] = useState(0);
        
    const prevSlideMobile = () => {
        const isFirstSlideMobile = currentIndexMobile === 0;
        const newIndexMobile = isFirstSlideMobile ? slidesMobile.length - 1 : currentIndexMobile - 1;
        setCurrentIndexMobile(newIndexMobile);
    }

    const nextSlideMobile = () => {
        const isLastSlideMobile = currentIndexMobile === slidesMobile.length - 1;
        const newIndexMobile = isLastSlideMobile ? 0 : currentIndexMobile + 1;
        setCurrentIndexMobile(newIndexMobile);
    }


    const goToSlide = (slideIndex) => {
        setCurrentIndexMobile(slideIndex);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentIndex===4){
                setCurrentIndex(0)
            }else{
                setCurrentIndex(currentIndex+1)
            }
        }, 10000)
        return () => clearTimeout(timer)
    }, [currentIndex])
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentIndexMobile===4){
                setCurrentIndexMobile(0)
            }else{
                setCurrentIndexMobile(currentIndexMobile+1)
            }
        }, 10000)
        return () => clearTimeout(timer)
    }, [currentIndexMobile])

  return (
    <div className='body w-[100%] h-[720px] lg:h-[770px] relative group pt-[70px]'>

       
            <div style={{backgroundImage: `url(${slides[currentIndex].image})`}} className='land--page back-pictures w-full bg-contain bg-no-repeat duration-1000 h-full hidden lg:block'></div>
            
            <div className='hidden lg:group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-0 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer active:bg-white/40 hover:bg-white/20 duration-200 index-[999]'>
                <BsChevronCompactLeft size={30} onClick={prevSlide} />
            </div>
            <div className='hidden lg:group-hover:block absolute top-[50%] translate-x-0 translate-y-[-50%] right-0 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer active:bg-white/40 hover:bg-white/20 duration-200 index-[999]'>
                <BsChevronCompactRight size={30} onClick={nextSlide}/>
            </div>

        
            <div style={{backgroundImage: `url(${slidesMobile[currentIndexMobile].image})`}} className='land--pages back-pictures w-full bg-contain bg-no-repeat duration-1000 h-full lg:hidden'></div>
       


        {/*<div className='absolute m-auto lg:top-[50%] top-20 bottom-0 left-0 right-0 scale-x-[70%] scale-y-[70%] lg:scale-x-[100%] lg:scale-y-[100%]z-[200]'>
            <Search />
         </div>*/}

        <div className='dot--navigate flex lg:hidden justify-center absolute top-[80%] m-auto top-80 bottom-15 left-0 right-0'>
        {slides.map((slide, slideIndex) => (
            <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className={currentIndexMobile===slideIndex + 1 ? 'dot active' : 'dot'}>
                <RxDotFilled />
            </div>
        ))}
        </div>

    </div>
    );
}

export default HomeSlider;
